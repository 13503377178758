import { CaseContext } from "../../common/types/models";
import { CopyToClipboard, StatusIndicator } from "@cloudscape-design/components";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import { FunctionComponent } from "react";

interface CaseInfoViewProps {
  caseContext?: CaseContext;
}

export const CaseInfoView: FunctionComponent<CaseInfoViewProps> = ({ caseContext }) => {
  if (!caseContext) {
    return null;
  }
  return (
    <KeyValuePairs
      columns={3}
      items={[
        {
          label: "Context ID",
          value: (
            <CopyToClipboard
              copyButtonAriaLabel="Copy Context ID"
              copyErrorText="Context ID failed to copy"
              copySuccessText="Context ID copied"
              textToCopy={caseContext.contextId}
              variant="inline"
            />
          ),
        },
        {
          label: "Name",
          value: caseContext.name,
        },
        {
          label: "Status",
          value: <StatusIndicator>{caseContext.status}</StatusIndicator>,
        },
        {
          label: "Description",
          value: caseContext.description,
        },
        {
          label: "Updated At",
          value: caseContext.updatedAt,
        },
        {
          label: "Created At",
          value: caseContext.createdAt,
        },
      ]}
    />
  );
};
