import axios from "axios";
import { EndpointConfigs } from "./endpointConfigs";
import { ArtifactMetadata, CaseContext, QueryRecord } from "../common/types/models";
import {
  CreateCaseContextRequest,
  DeleteCaseContextRequest,
  ListQueryRecordsRequest,
  GetCaseContextRequest,
  UpdateCaseContextRequest,
  UploadArtifactRequest,
  RemoveArtifactRequest,
  DeleteQueryRecordRequest,
  StartQueryRequest,
  UpdateArtifactMetadataRequest,
} from "../common/types/requests";

const CASE_CONTEXT_API_URL = EndpointConfigs.caseContextApiUrl();

export const listCaseContexts = async (accessToken: string): Promise<CaseContext[]> => {
  const response = await axios.post(
    `${CASE_CONTEXT_API_URL}/v1`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "ListCaseContexts",
        Authorization: accessToken,
      },
    }
  );
  return response.data.results as CaseContext[];
};

export const createCaseContext = async (
  accessToken: string,
  request: CreateCaseContextRequest
): Promise<CaseContext> => {
  const response = await axios.post(`${CASE_CONTEXT_API_URL}/v1`, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "CreateCaseContext",
      Authorization: accessToken,
    },
  });
  return response.data.caseContext as CaseContext;
};

export const deleteCaseContext = async (accessToken: string, request: DeleteCaseContextRequest) => {
  const response = await axios.post(`${CASE_CONTEXT_API_URL}/v1`, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "DeleteCaseContext",
      Authorization: accessToken,
    },
  });
};

export const getCaseContext = async (accessToken: string, request: GetCaseContextRequest): Promise<CaseContext> => {
  const response = await axios.post(`${CASE_CONTEXT_API_URL}/v1`, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "GetCaseContext",
      Authorization: accessToken,
    },
  });
  return response.data.caseContext as CaseContext;
};

export const updateCaseContext = async (
  accessToken: string,
  request: UpdateCaseContextRequest
): Promise<CaseContext> => {
  const response = await axios.post(`${CASE_CONTEXT_API_URL}/v1`, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "UpdateCaseContext",
      Authorization: accessToken,
    },
  });
  return response.data.caseContext as CaseContext;
};

export const uploadArtifact = async (accessToken: string, request: UploadArtifactRequest) => {
  const response = await axios.post(
    `${CASE_CONTEXT_API_URL}/v1`,
    `${request.content}===PART_SEPARATOR===${JSON.stringify({
      metadata: request.metadata,
    })}`,
    {
      headers: {
        "Content-Type": "text/plain",
        "X-Flux-Target": "UploadArtifact",
        Authorization: accessToken,
      },
    }
  );
  return response.data.metadata as ArtifactMetadata;
};

export const removeArtifact = async (accessToken: string, request: RemoveArtifactRequest) => {
  await axios.post(`${CASE_CONTEXT_API_URL}/v1`, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "RemoveArtifact",
      Authorization: accessToken,
    },
  });
};

export const listQueryRecords = async (
  accessToken: string,
  request: ListQueryRecordsRequest
): Promise<QueryRecord[]> => {
  const response = await axios.post(`${CASE_CONTEXT_API_URL}/v1`, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "ListQueryRecords",
      Authorization: accessToken,
    },
  });
  return response.data.records;
};

export const updateQueryRecord = async (accessToken: string, record: QueryRecord) => {
  const response = await axios.post(
    `${CASE_CONTEXT_API_URL}/v1`,
    {
      record,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "UpdateQueryRecord",
        Authorization: accessToken,
      },
    }
  );
  return response.data.record as QueryRecord;
};

export const deleteQueryRecord = async (accessToken: string, request: DeleteQueryRecordRequest) => {
  const response = await axios.post(`${CASE_CONTEXT_API_URL}/v1`, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "DeleteQueryRecord",
      Authorization: accessToken,
    },
  });
  return response.status;
};

export const getArtifactMetadata = async (accessToken: string, artifactId: string): Promise<ArtifactMetadata> => {
  const response = await axios.post(
    `${CASE_CONTEXT_API_URL}/v1`,
    {
      artifactId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "GetArtifactMetadata",
        Authorization: accessToken,
      },
    }
  );
  return response.data.metadata as ArtifactMetadata;
};

export const updateArtifactMetadata = async (
  accessToken: string,
  request: UpdateArtifactMetadataRequest
): Promise<ArtifactMetadata> => {
  const response = await axios.post(`${CASE_CONTEXT_API_URL}/v1`, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "UpdateArtifactMetadata",
      Authorization: accessToken,
    },
  });
  return response.data.metadata as ArtifactMetadata;
};

export const startQuery = async (accessToken: string, request: StartQueryRequest): Promise<QueryRecord> => {
  const response = await axios.post(`${CASE_CONTEXT_API_URL}/v1`, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "StartQuery",
      Authorization: accessToken,
    },
  });
  return response.data.record as QueryRecord;
};
