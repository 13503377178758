import { AuthUser, fetchAuthSession, getCurrentUser, signIn, updatePassword } from "aws-amplify/auth";
import axios from "axios";
import { EndpointConfigs } from "../api/endpointConfigs";
import { useEffect, useReducer } from "react";
import { Amplify } from "aws-amplify";

export interface CognitoConfig {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
}

export interface IAttribute {
  Name: string;
  Value: string;
}

export interface AuthState {
  cognitoConfig: CognitoConfig | undefined;
  cognitoLoading: boolean;
  authenticated: boolean;
  accessToken: string | undefined;
  authUser: AuthUser | undefined;
  error: Error | undefined;
}

export type AuthAction =
  | { type: "SET_CONFIG"; payload: CognitoConfig }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_AUTHENTICATED"; payload: boolean }
  | { type: "SET_ACCESS_TOKEN"; payload: string | undefined }
  | { type: "SET_AUTH_USER"; payload: AuthUser | undefined }
  | { type: "SET_ERROR"; payload: Error | undefined };

/**
 * Custom hook for handling AWS Cognito authentication
 * @returns Authentication methods and state
 */
export const useCognito = () => {
  const initialState: AuthState = {
    cognitoConfig: undefined,
    cognitoLoading: true,
    authenticated: false,
    accessToken: undefined,
    authUser: undefined,
    error: undefined,
  };

  // Reducer for handling auth state changes
  const authReducer = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
      case "SET_CONFIG":
        return { ...state, cognitoConfig: action.payload };
      case "SET_LOADING":
        return { ...state, cognitoLoading: action.payload };
      case "SET_AUTHENTICATED":
        return { ...state, authenticated: action.payload };
      case "SET_ACCESS_TOKEN":
        return { ...state, accessToken: action.payload };
      case "SET_AUTH_USER":
        return { ...state, authUser: action.payload };
      case "SET_ERROR":
        return { ...state, error: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  /**
   * Configure Amplify with Cognito settings
   * @param config The Cognito configuration
   */
  const configureAmplify = (config: CognitoConfig): void => {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolClientId: config.userPoolWebClientId,
          userPoolId: config.userPoolId,
          loginWith: {
            username: true,
          },
        },
      },
    });
  };

  /**
   * Fetch Cognito configuration from API
   */
  const fetchCognitoConfig = async (): Promise<void> => {
    try {
      const response = await axios.get(EndpointConfigs.authApiUrl());
      const config: CognitoConfig = response.data;

      dispatch({ type: "SET_CONFIG", payload: config });
      configureAmplify(config);

      const session = await fetchAuthSession();
      if (session.tokens !== undefined) {
        dispatch({ type: "SET_AUTHENTICATED", payload: true });
        dispatch({ type: "SET_ACCESS_TOKEN", payload: session.tokens.accessToken.toString() });
        await fetchCognitoUser();
      }
    } catch (error) {
      console.error("Error fetching Cognito config:", error);
      dispatch({ type: "SET_ERROR", payload: error instanceof Error ? error : new Error(String(error)) });
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  /**
   * Fetch the current authenticated Cognito user
   * @returns The authenticated user or undefined
   */
  const fetchCognitoUser = async (): Promise<AuthUser | undefined> => {
    try {
      const user = await getCurrentUser();
      dispatch({ type: "SET_AUTH_USER", payload: user });
      return user;
    } catch (error) {
      dispatch({ type: "SET_ERROR", payload: error instanceof Error ? error : new Error(String(error)) });
      return undefined;
    }
  };

  useEffect(() => {
    fetchCognitoConfig();
  }, []);

  /**
   * Authenticate user with username and password
   * @param username User's username
   * @param password User's password
   * @returns Whether authentication was successful
   */
  const authenticate = async (username: string, password: string): Promise<boolean> => {
    try {
      const { isSignedIn, nextStep } = await signIn({
        username,
        password,
      });
      dispatch({ type: "SET_AUTHENTICATED", payload: isSignedIn });

      if (isSignedIn) {
        const session = await fetchAuthSession();
        if (session.tokens) {
          dispatch({ type: "SET_ACCESS_TOKEN", payload: session.tokens.accessToken.toString() });
          await fetchCognitoUser();
        }
      }

      return isSignedIn;
    } catch (error) {
      dispatch({ type: "SET_ERROR", payload: error instanceof Error ? error : new Error(String(error)) });
      return false;
    }
  };

  /**
   * Change the user's password
   * @param oldPassword Current password
   * @param newPassword New password
   * @returns Whether the password change was successful
   */
  const changeUserPassword = async (oldPassword: string, newPassword: string): Promise<boolean> => {
    try {
      await updatePassword({ oldPassword, newPassword });
      return true;
    } catch (error) {
      dispatch({ type: "SET_ERROR", payload: error instanceof Error ? error : new Error(String(error)) });
      return false;
    }
  };

  return {
    authUser: state.authUser,
    cognitoConfig: state.cognitoConfig,
    accessToken: state.accessToken,
    cognitoLoading: state.cognitoLoading,
    authenticated: state.authenticated,
    error: state.error,
    authenticate,
    fetchCognitoUser,
    changeUserPassword,
  };
};
