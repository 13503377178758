import Select, { SelectProps } from "@cloudscape-design/components/select";
import { FluxWidgetProps } from "../../common/standards";
import { CaseContext } from "../../common/types/models";
import { FunctionComponent, useEffect, useState, useCallback } from "react";
import { createFluxNotification } from "../../common/helpers";
import { listCaseContexts } from "../../api/caseContext";

export interface CaseContextSelectorProps extends FluxWidgetProps {
  onCaseSelected: (selectedCase: CaseContext | undefined) => void;
  caseStatus?: string; // Optional parameter to filter cases by status
}

const caseContextToSelectOption = (caseContext: CaseContext): SelectProps.Option => ({
  label: caseContext.name,
  value: caseContext.contextId,
  description: `${caseContext.status} - ${caseContext.description || "No description"}`,
  tags: [caseContext.contextId],
});

export const CaseContextSelector: FunctionComponent<CaseContextSelectorProps> = ({
  onCaseSelected: onCaseContextSelected,
  setNotifications,
  accessToken,
  caseStatus,
}) => {
  const [caseContexts, setCaseContexts] = useState<CaseContext[]>([]);
  const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchCaseContexts = useCallback(
    async (token: string) => {
      setLoading(true);
      try {
        const cases = await listCaseContexts(token);
        setCaseContexts(cases);
      } catch (error) {
        createFluxNotification({
          header: "Failed to fetch cases",
          content: error instanceof Error ? error.message : "Unknown error occurred",
          type: "error",
          id: "selector_fetch_cases_error",
          setNotifications,
        });
      } finally {
        setLoading(false);
      }
    },
    [setNotifications, caseStatus]
  );

  const handleSelectChange = useCallback(
    (event: SelectProps.ChangeDetail) => {
      setSelectedOption(event.selectedOption);
      const selectedCase = caseContexts.find((caseItem) => caseItem.contextId === event.selectedOption.value);
      if (selectedCase) {
        onCaseContextSelected(selectedCase);
      }
    },
    [caseContexts, onCaseContextSelected]
  );

  useEffect(() => {
    if (accessToken) {
      fetchCaseContexts(accessToken);
    }
  }, [accessToken, fetchCaseContexts]);

  return (
    <Select
      selectedOption={selectedOption}
      onChange={(e) => handleSelectChange(e.detail)}
      options={caseContexts.map(caseContextToSelectOption)}
      placeholder="Select case"
      selectedAriaLabel="Selected"
      filteringType="auto"
      loadingText="Loading cases..."
      statusType={loading ? "loading" : "finished"}
      empty="No cases found"
    />
  );
};
