import Wizard from "@cloudscape-design/components/wizard";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import { FunctionComponent, useState, useMemo, useCallback, useEffect } from "react";
import Link from "@cloudscape-design/components/link";
import Container from "@cloudscape-design/components/container";
import FormField from "@cloudscape-design/components/form-field";
import FileUpload from "@cloudscape-design/components/file-upload";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import Alert from "@cloudscape-design/components/alert";
import Form from "@cloudscape-design/components/form";
import Select from "@cloudscape-design/components/select";

import { FluxWidgetProps } from "../../common/standards";
import { createFluxNotification, encodeBase64RemovePrefix, renderErrorMessage } from "../../common/helpers";
import Input from "@cloudscape-design/components/input";
import { createCaseContext, updateCaseContext, uploadArtifact } from "../../api/caseContext";
import { SourceType } from "../../common/enums";
import { FileTokenGroup } from "@cloudscape-design/components";

interface ValidationState {
  isValid: boolean;
  errorMessage: string | null;
}

interface FormErrors {
  caseName: ValidationState;
  caseDescription: ValidationState;
  documentName: ValidationState;
}

export interface CreateCaseWizardProps extends FluxWidgetProps {
  onSubmit: () => void;
  onCancel: () => void;
}

interface DocumentUpload {
  sourceType: SourceType;
  file: File;
  name: string;
}

export const CreateCaseWizard: FunctionComponent<CreateCaseWizardProps> = ({
  onSubmit,
  onCancel,
  setNotifications,
  accessToken,
}) => {
  const [caseName, setCaseName] = useState("");
  const [caseDescription, setCaseDescription] = useState("");

  const [currentFile, setCurrentFile] = useState<File[]>([]);
  const [documentName, setDocumentName] = useState("");
  const [selectedSourceType, setSelectedSourceType] = useState<SourceType>(SourceType.TERM_SHEET);

  const [uploadedDocuments, setUploadedDocuments] = useState<DocumentUpload[]>([]);

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({
    caseName: { isValid: true, errorMessage: null },
    caseDescription: { isValid: true, errorMessage: null },
    documentName: { isValid: true, errorMessage: null },
  });

  const sourceTypeOptions = [
    { label: "Term Sheet", value: SourceType.TERM_SHEET },
    { label: "Operating Statement", value: SourceType.OPERATING_STATEMENT },
    { label: "Rent Roll", value: SourceType.RENT_ROLL },
    { label: "Appraisal", value: SourceType.APPRAISAL },
    { label: "Personal Financial Statement", value: SourceType.PERSONAL_FINANCIAL_STATEMENT },
    { label: "Sponsor Background", value: SourceType.SPONSOR_BACKGROUND },
    { label: "Borrower Background", value: SourceType.BORROWER_BACKGROUND },
  ];

  const sourceTypeLabels = useMemo(() => {
    return sourceTypeOptions.reduce((acc, option) => {
      acc[option.value] = option.label;
      return acc;
    }, {} as Record<string, string>);
  }, [sourceTypeOptions]);

  const validateCaseName = useCallback((name: string): ValidationState => {
    if (!name.trim()) {
      return { isValid: false, errorMessage: "Case name is required" };
    }
    if (name.length < 3) {
      return { isValid: false, errorMessage: "Case name must be at least 3 characters" };
    }
    if (name.length > 100) {
      return { isValid: false, errorMessage: "Case name must be less than 100 characters" };
    }
    return { isValid: true, errorMessage: null };
  }, []);

  const validateCaseDescription = useCallback((description: string): ValidationState => {
    if (description.length > 500) {
      return { isValid: false, errorMessage: "Description must be less than 500 characters" };
    }
    return { isValid: true, errorMessage: null };
  }, []);

  const validateDocumentName = useCallback(
    (name: string): ValidationState => {
      if (!name.trim() && currentFile.length > 0) {
        return { isValid: false, errorMessage: "Document name is required" };
      }
      if (name.length > 100) {
        return { isValid: false, errorMessage: "Document name must be less than 100 characters" };
      }
      return { isValid: true, errorMessage: null };
    },
    [currentFile]
  );

  const validateForm = useCallback(() => {
    const nameValidation = validateCaseName(caseName);
    const descriptionValidation = validateCaseDescription(caseDescription);
    const docNameValidation = validateDocumentName(documentName);

    setFormErrors({
      caseName: nameValidation,
      caseDescription: descriptionValidation,
      documentName: docNameValidation,
    });

    return nameValidation.isValid && descriptionValidation.isValid && docNameValidation.isValid;
  }, [caseName, caseDescription, documentName, validateCaseName, validateCaseDescription, validateDocumentName]);

  const isFormValid = useMemo(() => {
    return formErrors.caseName.isValid && formErrors.caseDescription.isValid && caseName.trim() !== "";
  }, [formErrors, caseName]);

  const reset = () => {
    setCaseName("");
    setCaseDescription("");
    setCurrentFile([]);
    setDocumentName("");
    setUploadedDocuments([]);
    setActiveStepIndex(0);
    setLoading(false);
    setFormErrors({
      caseName: { isValid: true, errorMessage: null },
      caseDescription: { isValid: true, errorMessage: null },
      documentName: { isValid: true, errorMessage: null },
    });
  };

  const handleAddDocument = useCallback(() => {
    if (currentFile.length === 0 || !documentName.trim()) {
      setFormErrors((prev) => ({
        ...prev,
        documentName: validateDocumentName(documentName),
      }));
      return;
    }

    const newDocument: DocumentUpload = {
      sourceType: selectedSourceType,
      file: new File(currentFile, documentName, {
        type: currentFile[0].type,
        lastModified: currentFile[0].lastModified,
      }),
      name: documentName,
    };

    setUploadedDocuments((prev) => [...prev, newDocument]);

    setCurrentFile([]);
    setDocumentName("");
  }, [currentFile, documentName, selectedSourceType, validateDocumentName]);

  const handleRemoveDocument = useCallback((index: number) => {
    setUploadedDocuments((prev) => prev.filter((_, i) => i !== index));
  }, []);

  useEffect(() => {
    if (currentFile.length > 0) {
      const file = currentFile[0];
      const fileName = file.name.split(".").slice(0, -1).join(".");
      if (!documentName.trim()) {
        setDocumentName(fileName);
        setFormErrors((prev) => ({
          ...prev,
          documentName: validateDocumentName(fileName),
        }));
      }
    }
  }, [currentFile, validateDocumentName]);

  const handleSubmit = async () => {
    if (!accessToken) {
      createFluxNotification({
        header: "Missing authentication",
        content: "Please refresh the page and try again",
        type: "error",
        id: "auth_error",
        setNotifications,
      });
      return;
    }

    if (!validateForm() || uploadedDocuments.length === 0) {
      createFluxNotification({
        header: "Validation error",
        content: "Please fill in all required fields and add at least one document",
        type: "error",
        id: "validation_error",
        setNotifications,
      });
      return;
    }

    try {
      setLoading(true);

      const caseContext = await createCaseContext(accessToken, {
        name: caseName,
        description: caseDescription,
      });
      const sources = caseContext.sources || [];

      if (caseContext && caseContext.contextId) {
        for (const doc of uploadedDocuments) {
          try {
            const base64Content = await encodeBase64RemovePrefix(doc.file);

            const metadata = await uploadArtifact(accessToken, {
              content: base64Content,
              metadata: {
                name: doc.name,
                type: doc.sourceType,
                format: "PDF",
              },
            });
            if (metadata.artifactId) {
              sources.push(metadata.artifactId);
            }
          } catch (uploadError: any) {
            createFluxNotification({
              header: `Failed to upload ${doc.name}`,
              content: renderErrorMessage(uploadError),
              type: "warning",
              id: `failed_upload_${doc.name}`,
              setNotifications,
            });
          }
        }
        await updateCaseContext(accessToken, {
          caseContext: {
            ...caseContext,
            sources: sources,
          },
        });
      }

      createFluxNotification({
        header: "Case created successfully",
        content: "Your new case has been created",
        type: "success",
        id: "case_created",
        setNotifications,
      });

      onSubmit();
      reset();
    } catch (error: any) {
      createFluxNotification({
        header: "Failed to create case",
        content: renderErrorMessage(error),
        type: "error",
        id: "failed_to_create_case",
        setNotifications,
      });
    } finally {
      setLoading(false);
    }
  };

  const wizardSteps = [
    {
      title: "Basic Information",
      info: <Link variant="info">Info</Link>,
      description: "Provide some descriptions for the case",
      content: (
        <Container>
          <SpaceBetween direction="vertical" size="l">
            <FormField
              label="Case Name"
              errorText={!formErrors.caseName.isValid ? formErrors.caseName.errorMessage : undefined}
              description="Enter a descriptive name for your case (required)"
            >
              <Input
                value={caseName}
                placeholder="My Case Name"
                onChange={({ detail }) => {
                  const newValue = detail.value;
                  setCaseName(newValue);
                  setFormErrors((prev) => ({
                    ...prev,
                    caseName: validateCaseName(newValue),
                  }));
                }}
              />
            </FormField>
            <FormField
              label="Case Description"
              errorText={!formErrors.caseDescription.isValid ? formErrors.caseDescription.errorMessage : undefined}
              description="Enter a brief description of the case (optional)"
            >
              <Input
                value={caseDescription}
                placeholder="Simple description"
                onChange={({ detail }) => {
                  const newValue = detail.value;
                  setCaseDescription(newValue);
                  setFormErrors((prev) => ({
                    ...prev,
                    caseDescription: validateCaseDescription(newValue),
                  }));
                }}
              />
            </FormField>
          </SpaceBetween>
        </Container>
      ),
      isOptional: false,
    },
    {
      title: "Upload Source Documents",
      info: <Link variant="info">Info</Link>,
      description: "Add one or more source documents",
      content: (
        <Container>
          <SpaceBetween direction="vertical" size="l">
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={handleAddDocument}
                    disabled={!currentFile.length || !documentName.trim()}
                  >
                    Add Document
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween size="s">
                <FormField label="Document" description="Upload document from your device">
                  <FileUpload
                    onChange={({ detail }) => setCurrentFile(detail.value)}
                    value={currentFile}
                    i18nStrings={{
                      uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                      dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                      removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                      limitShowFewer: "Show fewer files",
                      limitShowMore: "Show more files",
                      errorIconAriaLabel: "Error",
                      warningIconAriaLabel: "Warning",
                    }}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    constraintText="PDF documents recommended"
                  />
                </FormField>
                <FormField
                  description="Readable name for your document"
                  label="Document Name"
                  errorText={!formErrors.documentName.isValid ? formErrors.documentName.errorMessage : undefined}
                >
                  <Input
                    value={documentName}
                    onChange={({ detail }) => {
                      const newValue = detail.value;
                      setDocumentName(newValue);
                      setFormErrors((prev) => ({
                        ...prev,
                        documentName: validateDocumentName(newValue),
                      }));
                    }}
                  />
                </FormField>
                <FormField label="Document Type">
                  <Select
                    options={sourceTypeOptions}
                    selectedOption={{
                      value: selectedSourceType,
                      label:
                        sourceTypeOptions.find((opt) => opt.value === selectedSourceType)?.label || selectedSourceType,
                    }}
                    onChange={({ detail }) => setSelectedSourceType(detail.selectedOption?.value as SourceType)}
                  />
                </FormField>
              </SpaceBetween>
            </Form>

            <div>
              <Header variant="h3">Uploaded Documents</Header>
              {uploadedDocuments.length === 0 ? (
                <Alert type="info">No documents added yet.</Alert>
              ) : (
                <FileTokenGroup
                  onDismiss={({ detail }) => handleRemoveDocument(detail.fileIndex)}
                  i18nStrings={{
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                    warningIconAriaLabel: "Warning",
                  }}
                  items={uploadedDocuments.map((doc, index) => ({ file: doc.file }))}
                  showFileLastModified
                  showFileSize
                />
              )}
            </div>
          </SpaceBetween>
        </Container>
      ),
    },
    {
      title: "Review and Create",
      content: (
        <SpaceBetween size="xs">
          <Header variant="h3" actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}>
            Case Information
          </Header>
          <KeyValuePairs
            columns={3}
            items={[
              {
                label: "Case Name",
                value: caseName,
              },
              {
                label: "Case Description",
                value: caseDescription || "(No description provided)",
              },
            ]}
          />
          <Header variant="h3" actions={<Button onClick={() => setActiveStepIndex(1)}>Edit</Button>}>
            Source Documents
          </Header>
          <KeyValuePairs
            columns={3}
            items={uploadedDocuments.map((doc) => ({
              label: sourceTypeLabels[doc.sourceType],
              value: doc.name,
            }))}
          />
          {uploadedDocuments.length === 0 && (
            <Alert type="warning">No documents have been added. Please go back and add at least one document.</Alert>
          )}
        </SpaceBetween>
      ),
    },
  ];

  return (
    <Container header={<Header variant="h2">Create New Case</Header>}>
      <Wizard
        onSubmit={handleSubmit}
        onCancel={() => {
          reset();
          onCancel();
        }}
        isLoadingNextStep={loading}
        i18nStrings={{
          stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Cancel",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Create",
          optional: "optional",
        }}
        onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
        activeStepIndex={activeStepIndex}
        allowSkipTo
        steps={wizardSteps}
      />
    </Container>
  );
};
