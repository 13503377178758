import { useCallback, useEffect, useState } from "react";
import { CaseContext, QueryRecord } from "../common/types/models";
import { listQueryRecords } from "../api/caseContext";

export const useQueryRecords = (accessToken: string | undefined, caseContext?: CaseContext) => {
  const [queryRecords, setQueryRecords] = useState<QueryRecord[]>([]);
  const [currentQueryRecord, setCurrentQueryRecord] = useState<QueryRecord | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const refreshQueryRecords = useCallback(async () => {
    if (!caseContext?.contextId || !accessToken) return;

    setLoading(true);
    try {
      const records = await listQueryRecords(accessToken, { contextId: caseContext?.contextId });
      setQueryRecords(records);
    } catch (error) {
      console.error("Failed to fetch query records:", error);
    } finally {
      setLoading(false);
    }
  }, [caseContext?.contextId, accessToken]);

  useEffect(() => {
    refreshQueryRecords();
  }, [refreshQueryRecords]);

  return {
    queryRecords,
    loading,
    refreshQueryRecords,
    currentQueryRecord,
    setCurrentQueryRecord,
  };
};
