import { FunctionComponent } from "react";
import { AnswerType } from "../../common/enums";
import Input from "@cloudscape-design/components/input";
import FormField from "@cloudscape-design/components/form-field";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Textarea from "@cloudscape-design/components/textarea";
import Select from "@cloudscape-design/components/select";
import { Answer } from "../../common/types/models";
import { FluxWidgetProps } from "../../common/standards";
import Container from "@cloudscape-design/components/container";
import { Button } from "@cloudscape-design/components";
import { QueryItem } from "./QueryRecordView";

export interface QueryItemViewProps extends FluxWidgetProps {
  item: QueryItem | null;
  inEdit: boolean;
  onChange: (a: QueryItem) => void;
}

// Helper component for answer editing
const AnswerEditor: FunctionComponent<{
  answer: Answer;
  index: number;
  inEdit: boolean;
  onUpdate: (updatedAnswer: Answer, index: number) => void;
}> = ({ answer, index, inEdit, onUpdate }) => (
  <SpaceBetween size="s">
    <FormField label="Value">
      <Textarea
        value={answer.value}
        disabled={!inEdit}
        onChange={(e) => onUpdate({ ...answer, value: e.detail.value }, index)}
      />
    </FormField>
    <FormField label="Unit">
      <Input
        value={answer.unit || ""}
        disabled={!inEdit}
        onChange={(e) => onUpdate({ ...answer, unit: e.detail.value }, index)}
      />
    </FormField>
    <FormField label="Type">
      <Select
        selectedOption={{
          value: answer.type,
          label: answer.type,
        }}
        disabled={!inEdit}
        options={Object.values(AnswerType).map((type) => ({ value: type, label: type }))}
        onChange={(e) => onUpdate({ ...answer, type: e.detail.selectedOption.value as AnswerType }, index)}
      />
    </FormField>
    <FormField label="Citation">
      <Textarea
        value={answer.citation || ""}
        disabled={!inEdit}
        onChange={(e) => onUpdate({ ...answer, citation: e.detail.value }, index)}
      />
    </FormField>
  </SpaceBetween>
);

export const QueryItemView: FunctionComponent<QueryItemViewProps> = ({ item, inEdit, onChange }) => {
  if (!item) return null;

  const updateAnswer = (updatedAnswer: Answer, index: number) => {
    onChange({
      ...item,
      answers: item.answers.map((answer, i) => (i === index ? updatedAnswer : answer)),
    });
  };

  const removeAnswer = (index: number) => {
    onChange({
      ...item,
      answers: item.answers.filter((_, i) => i !== index),
    });
  };

  const addAnswer = () => {
    onChange({
      ...item,
      answers: [
        ...item.answers,
        {
          value: "",
          unit: "",
          type: AnswerType.STRING,
          citation: "",
        },
      ],
    });
  };

  return (
    <SpaceBetween size="m">
      {item.answers.map((answer, index) => (
        <Container
          key={index}
          footer={
            <Button variant="link" iconName="remove" disabled={!inEdit} onClick={() => removeAnswer(index)}>
              Delete
            </Button>
          }
        >
          <AnswerEditor answer={answer} index={index} inEdit={inEdit} onUpdate={updateAnswer} />
        </Container>
      ))}
      <Button variant="normal" onClick={addAnswer} fullWidth disabled={!inEdit}>
        Add Item
      </Button>
    </SpaceBetween>
  );
};
