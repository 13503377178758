export enum ConsolePageIds {
  HOME = "home",
  CONFIGURATION = "configuration",
  CASE = "case",
  AUTH = "auth",
  PROFILE = "profile",
  REPORT = "report",
  MODEL = "model",
}

export interface RouteConfig {
  href: string;
  pageName: string;
}

export const ConsoleRouteConfig: Record<ConsolePageIds, RouteConfig> = {
  [ConsolePageIds.HOME]: { href: "/", pageName: "Home" },
  [ConsolePageIds.CONFIGURATION]: { href: "/configuration", pageName: "Configuration" },
  [ConsolePageIds.CASE]: { href: "/case", pageName: "Case" },
  [ConsolePageIds.AUTH]: { href: "/auth", pageName: "Authentication" },
  [ConsolePageIds.PROFILE]: { href: "/profile", pageName: "Profile" },
  [ConsolePageIds.REPORT]: { href: "/report", pageName: "Report" },
  [ConsolePageIds.MODEL]: { href: "/model", pageName: "Model" },
};
