import React, { FunctionComponent } from "react";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { ConsoleRouteConfig } from "../common/routes";
import Container from "@cloudscape-design/components/container";

/**
 * Format the current date in a user-friendly format
 * @returns Formatted date string (e.g., "January 1, 2023")
 */
const formatCurrentDate = (): string => {
  const date = new Date();
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

/**
 * Banner image configuration
 */
const bannerImageConfig = {
  src: "/images/flux_logo.png",
  alt: "Flux Logo",
  style: {
    padding: "40px",
    boxSizing: "border-box" as const,
  },
};

/**
 * Banner heading and content text
 */
const bannerContent = {
  heading: "Flux: Reinvent Commercial Underwriting for the Modern Bank",
  description:
    "An AI-powered solution that can automate the underwriting process for your bank without cutting corners.",
};

/**
 * Main banner component for the home page
 * Displays a featured image, date, heading, description, and call-to-action
 */
export const HomeBanner: FunctionComponent = () => {
  return (
    <Box padding={{ top: "xs", bottom: "l" }}>
      <Container
        media={{
          content: <img src={bannerImageConfig.src} alt={bannerImageConfig.alt} style={bannerImageConfig.style} />,
          height: 400,
          position: "top",
        }}
        footer={
          <div className="container-media-footer">
            <SpaceBetween size="s" direction="horizontal">
              <Button fullWidth={true} variant="primary" href={`#${ConsoleRouteConfig.case.href}`}>
                Get Started
              </Button>
            </SpaceBetween>
          </div>
        }
      >
        <SpaceBetween direction="vertical" size="s">
          <SpaceBetween direction="vertical" size="xxs">
            <Box variant="small">{formatCurrentDate()}</Box>
            <Box variant="h2">{bannerContent.heading}</Box>
          </SpaceBetween>
          <span>{bannerContent.description}</span>
        </SpaceBetween>
      </Container>
    </Box>
  );
};
