import React, { useEffect, useState, useCallback, useMemo, FunctionComponent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useCognito } from "../auth/cognito";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Flashbar, { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { ConsoleRouteConfig } from "../common/routes";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Link from "@cloudscape-design/components/link";
import { CreateReportWizard } from "../component/wizard/CreateReportWizard";
import { ReportTable } from "../component/table/ReportTable";
import { Report } from "../common/types/models";
import { listReports, getReport } from "../api/reportGeneration";
import { ReportBlock, ReportDetailView } from "../component/views/ReportDetailView";
import { createFluxNotification, renderErrorMessage } from "../common/helpers";
import { Box, Button, Spinner } from "@cloudscape-design/components";
import { FluxWidgetProps } from "../common/standards";

// Custom hook for managing report list
const useReportsList = (
  accessToken: string | undefined,
  setNotifications: React.Dispatch<React.SetStateAction<FlashbarProps.MessageDefinition[]>>
) => {
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const refreshReports = useCallback(async () => {
    if (!accessToken) return;

    setLoading(true);
    try {
      const fetchedReports = await listReports(accessToken);
      setReports(fetchedReports);
    } catch (error: any) {
      createFluxNotification({
        header: "Error fetching reports",
        content: renderErrorMessage(error),
        type: "error",
        id: "reports_fetch_error",
        setNotifications,
      });
    } finally {
      setLoading(false);
    }
  }, [accessToken, setNotifications]);

  useEffect(() => {
    if (accessToken) {
      refreshReports();
    }
  }, [accessToken, refreshReports]);

  return { reports, loading, refreshReports };
};

// Custom hook for managing report details
const useReportDetails = (
  reportId: string | undefined,
  accessToken: string | undefined,
  setNotifications: React.Dispatch<React.SetStateAction<FlashbarProps.MessageDefinition[]>>
) => {
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [reportBlocks, setReportBlocks] = useState<ReportBlock[]>([]);
  const [detailsLoading, setDetailsLoading] = useState<boolean>(true);

  const fetchReportDetails = useCallback(async () => {
    if (!reportId || !accessToken) return;

    setDetailsLoading(true);
    try {
      const response = await getReport(accessToken, { reportId });
      setSelectedReport(response.report);
      setReportBlocks(response.blocks || []);
    } catch (error: any) {
      createFluxNotification({
        header: "Error fetching report details",
        content: renderErrorMessage(error),
        type: "error",
        id: "report_details_error",
        setNotifications,
      });
      setSelectedReport(null);
      setReportBlocks([]);
    } finally {
      setDetailsLoading(false);
    }
  }, [reportId, accessToken, setNotifications]);

  useEffect(() => {
    fetchReportDetails();
  }, [fetchReportDetails]);

  return { selectedReport, reportBlocks, detailsLoading, fetchReportDetails };
};

interface ReportListViewProps extends FluxWidgetProps {
  reports: Report[];
  loading: boolean;
  onRefresh: () => Promise<void>;
}
const ReportListView: FunctionComponent<ReportListViewProps> = ({
  reports,
  loading,
  onRefresh,
  accessToken,
  notifications,
  setNotifications,
}) => (
  <SpaceBetween size="xl">
    <ReportTable
      onRefresh={onRefresh}
      onItemClicked={(item: Report) => {
        window.location.href = `#${ConsoleRouteConfig.report.href}/${item.reportId}`;
      }}
      loading={loading}
      items={reports}
      accessToken={accessToken}
      setNotifications={setNotifications}
    />
    <CreateReportWizard
      accessToken={accessToken}
      onSubmit={() => onRefresh()}
      onCancel={() => {}}
      notifications={notifications}
      setNotifications={setNotifications}
    />
  </SpaceBetween>
);

export const ReportView = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);

  const { cognitoLoading, authenticated, accessToken } = useCognito();

  useEffect(() => {
    if (cognitoLoading) return;
    if (!authenticated) {
      navigate(ConsoleRouteConfig.auth.href);
    }
  }, [cognitoLoading, authenticated, navigate]);

  const { reports, loading: reportsLoading, refreshReports } = useReportsList(accessToken, setNotifications);
  const { selectedReport, reportBlocks, detailsLoading } = useReportDetails(id, accessToken, setNotifications);

  const handleBackToReports = useCallback(() => {
    navigate(ConsoleRouteConfig.report.href);
  }, [navigate]);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      notifications={<Flashbar items={notifications} />}
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Report
        </Header>
      }
    >
      {!id && (
        <ReportListView
          reports={reports}
          loading={reportsLoading}
          onRefresh={refreshReports}
          accessToken={accessToken}
          notifications={notifications}
          setNotifications={setNotifications}
        />
      )}
      {id && selectedReport && detailsLoading && (
        <Container>
          <Box textAlign="center" padding={{ top: "l", bottom: "l" }}>
            <SpaceBetween size="s" direction="vertical" alignItems="center">
              <Spinner size="large" variant="normal" />
              <Box variant="p" color="text-status-info">
                Loading report details...
              </Box>
            </SpaceBetween>
          </Box>
        </Container>
      )}
      {id && selectedReport && !detailsLoading && (
        <ReportDetailView
          report={selectedReport}
          blocks={reportBlocks}
          accessToken={accessToken}
          notifications={notifications}
          setNotifications={setNotifications}
        />
      )}
      {id && !selectedReport && !detailsLoading && (
        <Container>
          <SpaceBetween size="m">
            <Header variant="h2">Report Not Found</Header>
            <p>The requested report could not be found or you don't have permission to view it.</p>
            <Button onClick={handleBackToReports}>Return to reports list</Button>
          </SpaceBetween>
        </Container>
      )}
    </ContentLayout>
  );
};
