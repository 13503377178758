import { useState, useEffect, useCallback } from "react";
import { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { listExcelModels, uploadExcelModel, deleteExcelModel } from "../api/reportGeneration";
import { createFluxNotification } from "../common/helpers";
import { ExcelModelMetadata } from "../common/types/models";

export const useExcelModels = (
  accessToken: string | undefined,
  setNotifications: React.Dispatch<React.SetStateAction<FlashbarProps.MessageDefinition[]>>
) => {
  const [models, setModels] = useState<ExcelModelMetadata[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchModels = useCallback(async () => {
    if (!accessToken) return;

    try {
      setLoading(true);
      const response = await listExcelModels(accessToken);
      if (response.models) {
        setModels(response.models);
      }
    } catch (error) {
      console.error("Failed to fetch Excel models:", error);
      createFluxNotification({
        header: "Failed to fetch Excel models",
        content: error instanceof Error ? error.message : "An unknown error occurred",
        type: "error",
        id: `fetch_models_error_${Date.now()}`,
        setNotifications,
      });
    } finally {
      setLoading(false);
    }
  }, [accessToken, setNotifications]);

  const uploadModel = useCallback(
    async (name: string, sheets: Record<string, string>) => {
      if (!accessToken) return null;

      try {
        setLoading(true);
        const response = await uploadExcelModel(accessToken, {
          name,
          sheets,
        });

        await fetchModels(); // Refresh models after upload

        createFluxNotification({
          header: "Excel model uploaded successfully",
          content: `Model "${name}" has been uploaded successfully.`,
          type: "success",
          id: `upload_model_success_${Date.now()}`,
          setNotifications,
        });

        return response.metadata;
      } catch (error) {
        console.error("Failed to upload Excel model:", error);
        createFluxNotification({
          header: "Failed to upload Excel model",
          content: error instanceof Error ? error.message : "An unknown error occurred",
          type: "error",
          id: `upload_model_error_${Date.now()}`,
          setNotifications,
        });
        return null;
      } finally {
        setLoading(false);
      }
    },
    [accessToken, fetchModels, setNotifications]
  );

  const removeModel = useCallback(
    async (modelId: string) => {
      if (!accessToken) return;

      try {
        setLoading(true);
        await deleteExcelModel(accessToken, { modelId });

        // Update the local state by filtering out the deleted model
        setModels((prevModels) => prevModels.filter((model) => model.modelId !== modelId));

        createFluxNotification({
          header: "Excel model deleted successfully",
          content: "The selected model has been deleted.",
          type: "success",
          id: `delete_model_success_${Date.now()}`,
          setNotifications,
        });
      } catch (error) {
        console.error("Failed to delete Excel model:", error);
        createFluxNotification({
          header: "Failed to delete Excel model",
          content: error instanceof Error ? error.message : "An unknown error occurred",
          type: "error",
          id: `delete_model_error_${Date.now()}`,
          setNotifications,
        });
      } finally {
        setLoading(false);
      }
    },
    [accessToken, setNotifications]
  );

  const batchRemoveModels = useCallback(
    async (modelIds: string[]) => {
      if (!accessToken) return;

      try {
        setLoading(true);
        await Promise.all(modelIds.map((modelId) => deleteExcelModel(accessToken, { modelId })));

        setModels((prevModels) => prevModels.filter((model) => !modelIds.includes(model.modelId)));

        createFluxNotification({
          header: "Excel models deleted successfully",
          content: "The selected models have been deleted.",
          type: "success",
          id: `delete_models_success_${Date.now()}`,
          setNotifications,
        });
      } catch (error) {
        console.error("Failed to delete Excel models:", error);
        createFluxNotification({
          header: "Failed to delete Excel models",
          content: error instanceof Error ? error.message : "An unknown error occurred",
          type: "error",
          id: `delete_models_error_${Date.now()}`,
          setNotifications,
        });
      } finally {
        setLoading(false);
      }
    },
    [accessToken, setNotifications]
  );

  useEffect(() => {
    if (accessToken) {
      fetchModels();
    }
  }, [accessToken, fetchModels]);

  return {
    models,
    loading,
    fetchModels,
    uploadModel,
    removeModel,
    batchRemoveModels,
  };
};
