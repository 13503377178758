import { FunctionComponent, useState, useCallback, useMemo } from "react";
import { FluxTableWidgetProps } from "../../common/standards";
import { QueryRecord } from "../../common/types/models";
import Table from "@cloudscape-design/components/table";
import Link from "@cloudscape-design/components/link";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import { Container, CopyToClipboard } from "@cloudscape-design/components";
import { renderShortUUID } from "../../common/helpers";
import { deleteQueryRecord } from "../../api/caseContext";

export interface QueryRecordTableProps extends FluxTableWidgetProps<QueryRecord> {}

// Custom hook to manage table operations
const useQueryTableOperations = (onRefresh?: () => Promise<void>, accessToken?: string) => {
  const [selectedItems, setSelectedItems] = useState<QueryRecord[]>([]);
  const [loadingStates, setLoadingStates] = useState({
    refreshing: false,
    deleting: false,
  });

  const handleRefresh = useCallback(() => {
    if (!onRefresh) return;

    setLoadingStates((prev) => ({ ...prev, refreshing: true }));
    onRefresh().finally(() => setLoadingStates((prev) => ({ ...prev, refreshing: false })));
  }, [onRefresh]);

  const handleDeleteQueryRecord = useCallback(
    async (queryRecord: QueryRecord) => {
      if (!accessToken) return;

      await deleteQueryRecord(accessToken, {
        queryId: queryRecord.queryId,
        contextId: queryRecord.contextId,
      });
      handleRefresh();
    },
    [accessToken, handleRefresh]
  );

  const handleBulkDelete = useCallback(async () => {
    if (selectedItems.length === 0) return;

    setLoadingStates((prev) => ({ ...prev, deleting: true }));
    await Promise.all(selectedItems.map(handleDeleteQueryRecord));
    setSelectedItems([]);
    setLoadingStates((prev) => ({ ...prev, deleting: false }));
  }, [selectedItems, handleDeleteQueryRecord]);

  return {
    selectedItems,
    setSelectedItems,
    loadingStates,
    handleRefresh,
    handleBulkDelete,
  };
};

export const QueryRecordTable: FunctionComponent<QueryRecordTableProps> = ({
  items,
  onItemClicked,
  onRefresh,
  accessToken,
  loading,
}) => {
  const {
    selectedItems,
    setSelectedItems,
    loadingStates: { refreshing, deleting },
    handleRefresh,
    handleBulkDelete,
  } = useQueryTableOperations(onRefresh, accessToken);

  // Define column definitions outside the main render for better clarity
  const columnDefinitions = useMemo(
    () => [
      {
        id: "queryId",
        header: "Query ID",
        cell: (item: QueryRecord) => (
          <Link onClick={() => onItemClicked(item)}>{renderShortUUID(item.queryId) || "-"}</Link>
        ),
        sortingField: "queryId",
        isRowHeader: true,
      },
      {
        id: "createdAt",
        header: "Created At",
        cell: (item: QueryRecord) => item.createdAt || "-",
        sortingField: "createdAt",
      },
      {
        id: "updatedAt",
        header: "Updated At",
        cell: (item: QueryRecord) => item.updatedAt || "-",
        sortingField: "updatedAt",
      },
    ],
    [onItemClicked]
  );

  // Empty state component for better readability
  const emptyState = (
    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
      <SpaceBetween size="m">
        <b>No records</b>
      </SpaceBetween>
    </Box>
  );

  // Table header actions
  const headerActions = (
    <SpaceBetween size="xs" direction="horizontal">
      <Button onClick={handleRefresh} iconName="refresh" loading={refreshing} disabled={!onRefresh} />
      <Button loading={deleting} onClick={handleBulkDelete} disabled={selectedItems.length === 0}>
        Delete
      </Button>
    </SpaceBetween>
  );

  return (
    <Container>
      <Table
        loading={loading}
        columnDefinitions={columnDefinitions}
        enableKeyboardNavigation
        items={items}
        loadingText="Loading resources"
        sortingDisabled
        variant="embedded"
        selectionType="multi"
        trackBy="queryId"
        selectedItems={selectedItems}
        onSelectionChange={(e) => setSelectedItems(e.detail.selectedItems)}
        empty={emptyState}
        header={
          <Header variant="h2" actions={headerActions}>
            Query Records
          </Header>
        }
      />
    </Container>
  );
};
