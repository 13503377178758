import Wizard from "@cloudscape-design/components/wizard";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import { FunctionComponent, useEffect, useState } from "react";
import Link from "@cloudscape-design/components/link";
import Container from "@cloudscape-design/components/container";
import FormField from "@cloudscape-design/components/form-field";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import Input from "@cloudscape-design/components/input";
import Select from "@cloudscape-design/components/select";

import { FluxWidgetProps } from "../../common/standards";
import { createContextFromQueryRecords, createFluxNotification, renderErrorMessage } from "../../common/helpers";
import { listCaseContexts, listQueryRecords } from "../../api/caseContext";
import { listConfigurations } from "../../api/configurations";
import { CaseContext, Configuration, ReportDefinition, ReportMetadata } from "../../common/types/models";
import { SelectProps } from "@cloudscape-design/components/select/interfaces";
import { startWorkflow } from "../../api/reportGeneration";
import { useCognito } from "../../auth/cognito";
import { CaseInfoView } from "../views/CaseInfoView";
import { ConfigurationSelector } from "../misc/ConfigurationSelector";

export interface CreateReportWizardProps extends FluxWidgetProps {
  onSubmit: () => void;
  onCancel: () => void;
}

export const CreateReportWizard: FunctionComponent<CreateReportWizardProps> = ({
  onSubmit,
  onCancel,
  setNotifications,
  notifications,
  accessToken,
}) => {
  const { authUser } = useCognito();

  const [reportName, setReportName] = useState("Untitled");
  const [reportDescription, setReportDescription] = useState("");
  const [reportId, setReportId] = useState("");

  const [reportNameError, setReportNameError] = useState("");
  const [reportIdError, setReportIdError] = useState("");

  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [selectedConfiguration, setSelectedConfiguration] = useState<SelectProps.Option | null>(null);
  const [configurationsLoading, setConfigurationsLoading] = useState(false);

  const [caseContexts, setCaseContexts] = useState<CaseContext[]>([]);

  const [selectedCase, setSelectedCase] = useState<SelectProps.Option | null>(null);
  const [casesLoading, setCasesLoading] = useState(false);

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchConfigurations = async () => {
    if (!accessToken) return;
    try {
      setConfigurationsLoading(true);
      const configs = await listConfigurations(accessToken);
      setConfigurations(configs);
    } catch (error: any) {
      createFluxNotification({
        header: "Failed to load configurations",
        content: renderErrorMessage(error),
        type: "error",
        id: "failed_to_load_configurations",
        setNotifications,
      });
    } finally {
      setConfigurationsLoading(false);
    }
  };

  const fetchCases = async () => {
    if (!accessToken) return;
    try {
      setCasesLoading(true);
      const contexts = await listCaseContexts(accessToken);
      setCaseContexts(contexts);
    } catch (error: any) {
      createFluxNotification({
        header: "Failed to load cases",
        content: renderErrorMessage(error),
        type: "error",
        id: "failed_to_load_cases",
        setNotifications,
      });
    } finally {
      setCasesLoading(false);
    }
  };

  useEffect(() => {
    fetchConfigurations();
  }, [accessToken]);

  useEffect(() => {
    fetchCases();
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      const today = new Date().toISOString().split("T")[0];
      const generatedId = `CR-${today}-${reportName.replace(/\s+/g, "-")}-${Math.floor(Math.random() * 10000)}`;
      setReportId(generatedId);
      setReportIdError("");
    }
  }, [accessToken, reportName, authUser]);

  const reset = () => {
    setReportName("Untitled");
    setReportDescription("");
    setReportId("");
    setSelectedConfiguration(null);
    setSelectedCase(null);
    setActiveStepIndex(0);
    setLoading(false);
    setReportNameError("");
    setReportIdError("");
  };

  const validateBasicInfo = () => {
    let isValid = true;

    if (!reportName || reportName.trim() === "") {
      setReportNameError("Report name is required");
      isValid = false;
    } else {
      setReportNameError("");
    }

    if (!reportId || reportId.trim() === "") {
      setReportIdError("Custom ID is required");
      isValid = false;
    } else {
      setReportIdError("");
    }

    return isValid;
  };

  const handleStepChange = (requestedStepIndex: number) => {
    if (activeStepIndex === 0 && requestedStepIndex !== 0) {
      if (!validateBasicInfo()) {
        return;
      }
    }
    setActiveStepIndex(requestedStepIndex);
  };

  const handleSubmit = async () => {
    if (!accessToken || !selectedConfiguration || !selectedCase) {
      createFluxNotification({
        header: "Missing required information",
        content: "Please select both a configuration and a case",
        type: "error",
        id: "missing_required_info",
        setNotifications,
      });
      return;
    }

    try {
      setLoading(true);
      if (selectedCase.value) {
        const queryRecords = await listQueryRecords(accessToken, {
          contextId: selectedCase.value,
        });
        const caseContext = caseContexts.find((context) => context.contextId === selectedCase.value);
        const context = createContextFromQueryRecords(queryRecords);
        const reportDefinition: ReportDefinition = JSON.parse(
          configurations.find((config) => config.configurationId === selectedConfiguration.value)?.content || "{}"
        );
        const metadata: ReportMetadata = {
          name: reportName,
          customId: reportId,
        };
        if (reportDescription && reportDescription.length != 0) {
          metadata.description = reportDescription;
        }

        const response = await startWorkflow(accessToken, {
          workflow: "CreateReport",
          parameters: JSON.stringify({
            context: context,
            artifacts: caseContext?.artifacts || [],
            reportDefinition: reportDefinition,
            metadata: metadata,
          }),
        });
        createFluxNotification({
          header: "Report workflow started",
          content: `You can track the progress of the report with ID: ${response.information}`,
          type: "success",
          id: "report_create_success",
          setNotifications,
        });
      }
    } catch (error: any) {
      createFluxNotification({
        header: "Failed to create report",
        content: renderErrorMessage(error),
        type: "error",
        id: "failed_to_create_report",
        setNotifications,
      });
    } finally {
      setLoading(false);
      onSubmit();
      reset();
    }
  };

  const configurationOptions = configurations.map((config) => ({
    label: config.name || config.configurationId,
    value: config.configurationId,
    description: config.description || "No description",
  }));

  const caseOptions = caseContexts.map((caseContext) => ({
    label: caseContext.name || caseContext.contextId,
    value: caseContext.contextId,
    description: caseContext.description || "No description",
  }));

  return (
    <Container header={<Header variant="h2">Create New Report</Header>}>
      <Wizard
        onSubmit={handleSubmit}
        onCancel={() => {
          reset();
          onCancel();
        }}
        isLoadingNextStep={loading}
        i18nStrings={{
          stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Cancel",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Create Report",
          optional: "optional",
        }}
        onNavigate={({ detail }) => handleStepChange(detail.requestedStepIndex)}
        activeStepIndex={activeStepIndex}
        allowSkipTo
        steps={[
          {
            title: "Basic Information",
            info: <Link variant="info">Info</Link>,
            description: "Provide details for the report",
            content: (
              <Container>
                <SpaceBetween direction="vertical" size="l">
                  <FormField label="Report Name" errorText={reportNameError}>
                    <Input
                      value={reportName}
                      placeholder="My Report Name"
                      onChange={(event) => {
                        setReportName(event.detail.value);
                        if (event.detail.value) {
                          setReportNameError("");
                        }
                      }}
                    />
                  </FormField>
                  <FormField label="Custom ID" errorText={reportIdError}>
                    <Input
                      value={reportId}
                      placeholder="Custom identifier"
                      onChange={(event) => {
                        setReportId(event.detail.value);
                        if (event.detail.value) {
                          setReportIdError("");
                        }
                      }}
                    />
                  </FormField>
                  <FormField label="Report Description">
                    <Input
                      value={reportDescription}
                      placeholder="Simple description"
                      onChange={(event) => setReportDescription(event.detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: "Select Configuration",
            info: <Link variant="info">Info</Link>,
            description: "Choose a configuration to base your report on",
            content: (
              <Container>
                <SpaceBetween direction="vertical" size="l">
                  <FormField
                    label="Configuration"
                    description="Select the configuration that defines how to create the report"
                  >
                    <ConfigurationSelector
                      accessToken={accessToken}
                      onConfigurationSelected={(config) => {
                        if (config) {
                          setSelectedConfiguration({
                            label: config.name,
                            value: config.configurationId,
                            description: config.description || "",
                          });
                        }
                      }}
                      setNotifications={setNotifications}
                      notifications={notifications}
                      configurationType="Report"
                    />
                  </FormField>

                  {selectedConfiguration && (
                    <KeyValuePairs
                      items={[
                        {
                          label: "Configuration ID",
                          value: selectedConfiguration.value,
                        },
                        {
                          label: "Description",
                          value: selectedConfiguration.description || "No description",
                        },
                      ]}
                    />
                  )}
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: "Select Case",
            info: <Link variant="info">Info</Link>,
            description: "Choose a case containing the source documents and artifacts",
            content: (
              <Container>
                <SpaceBetween direction="vertical" size="l">
                  <FormField label="Case Context" description="Select the case from which to create the report">
                    <Select
                      selectedOption={selectedCase}
                      onChange={({ detail }) => setSelectedCase(detail.selectedOption)}
                      options={caseOptions}
                      filteringType="auto"
                      placeholder="Select a case"
                      empty="No cases available"
                      loadingText="Loading cases"
                      statusType={casesLoading ? "loading" : "finished"}
                    />
                  </FormField>

                  {selectedCase && (
                    <CaseInfoView
                      caseContext={caseContexts.find((context) => context.contextId === selectedCase.value)}
                    />
                  )}
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: "Review and Create",
            content: (
              <SpaceBetween size="l">
                <Container
                  header={
                    <Header variant="h3" actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}>
                      General Information
                    </Header>
                  }
                >
                  <KeyValuePairs
                    columns={3}
                    items={[
                      {
                        label: "Report Name",
                        value: reportName || "-",
                      },
                      {
                        label: "Report ID",
                        value: reportId || "-",
                      },
                      {
                        label: "Report Description",
                        value: reportDescription || "-",
                      },
                    ]}
                  />
                </Container>

                <Container
                  header={
                    <Header variant="h3" actions={<Button onClick={() => setActiveStepIndex(1)}>Edit</Button>}>
                      Configuration
                    </Header>
                  }
                >
                  <KeyValuePairs
                    columns={3}
                    items={[
                      {
                        label: "Configuration Name",
                        value: selectedConfiguration ? selectedConfiguration.label : "-",
                      },
                      {
                        label: "Configuration ID",
                        value: selectedConfiguration ? selectedConfiguration.value : "-",
                      },
                    ]}
                  />
                </Container>

                <Container
                  header={
                    <Header variant="h3" actions={<Button onClick={() => setActiveStepIndex(2)}>Edit</Button>}>
                      Case
                    </Header>
                  }
                >
                  <CaseInfoView
                    caseContext={caseContexts.find((context) => context.contextId === selectedCase?.value)}
                  />
                </Container>
              </SpaceBetween>
            ),
          },
        ]}
      />
    </Container>
  );
};
