import { ConfigurationTable } from "../component/table/ConfigurationTable";
import { useEffect, useState, useCallback, FunctionComponent } from "react";
import { Configuration } from "../common/types/models";
import { useCognito } from "../auth/cognito";
import { ConsoleRouteConfig } from "../common/routes";
import { CreateConfigurationWizard } from "../component/wizard/CreateConfigurationWizard";
import { createConfiguration, listConfigurations } from "../api/configurations";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Link from "@cloudscape-design/components/link";
import { useParams, useNavigate } from "react-router-dom";
import { ConfigurationEditor } from "../component/views/ConfigurationEditor";
import Flashbar, { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { FluxWidgetProps } from "../common/standards";

interface ConfigurationOverviewProps extends FluxWidgetProps {
  configurations: Configuration[];
  dataLoading: boolean;
  onRefresh: () => Promise<void>;
  accessToken: string | undefined;
}

const ConfigurationOverview: FunctionComponent<ConfigurationOverviewProps> = ({
  configurations,
  dataLoading,
  onRefresh,
  accessToken,
}) => (
  <SpaceBetween size="xl">
    <ConfigurationTable
      items={configurations}
      loading={dataLoading}
      onRefresh={onRefresh}
      accessToken={accessToken}
      onItemClicked={(item) => {
        window.location.href = `#${ConsoleRouteConfig.configuration.href}/${item.configurationId}`;
      }}
    />
    <CreateConfigurationWizard
      onSubmit={async (request) => {
        try {
          if (accessToken) await createConfiguration(accessToken, request);
          onRefresh();
        } catch (error) {
          console.error("Failed to create configuration", error);
        }
      }}
      onCancel={() => {}}
    />
  </SpaceBetween>
);

export const ConfigurationView: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [dataLoading, setDataLoading] = useState(false);
  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const navigate = useNavigate();

  const { cognitoLoading, authenticated, accessToken } = useCognito();

  useEffect(() => {
    if (cognitoLoading) return;
    if (!authenticated) {
      navigate(ConsoleRouteConfig.auth.href);
    }
  }, [cognitoLoading, authenticated, navigate]);

  const refreshConfigurations = useCallback(async () => {
    if (!accessToken) return;
    setDataLoading(true);
    setError(null);
    try {
      const fetchedConfigurations = await listConfigurations(accessToken);
      setConfigurations(fetchedConfigurations);
    } catch (err) {
      console.error("Failed to fetch configurations", err);
      setError("Failed to load configurations. Please try again.");
    } finally {
      setDataLoading(false);
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      refreshConfigurations();
    }
  }, [accessToken, refreshConfigurations]);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      notifications={<Flashbar items={notifications} />}
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Configuration
          {error && <span style={{ color: "red", marginLeft: "10px" }}>{error}</span>}
        </Header>
      }
    >
      {id ? (
        <ConfigurationEditor
          configurationId={id}
          accessToken={accessToken}
          notifications={notifications}
          setNotifications={setNotifications}
        />
      ) : (
        <ConfigurationOverview
          configurations={configurations}
          dataLoading={dataLoading}
          onRefresh={refreshConfigurations}
          accessToken={accessToken}
        />
      )}
    </ContentLayout>
  );
};
