import Select, { SelectProps } from "@cloudscape-design/components/select";
import { FluxWidgetProps } from "../../common/standards";
import { Configuration } from "../../common/types/models";
import { FunctionComponent, useEffect, useState, useCallback } from "react";
import { listConfigurations } from "../../api/configurations";
import { createFluxNotification } from "../../common/helpers";

export interface ConfigurationSelectorProps extends FluxWidgetProps {
  onConfigurationSelected: (configuration: Configuration | undefined) => void;
  configurationType?: string; // Optional parameter to filter configurations by type
}

const configurationToSelectOption = (cfg: Configuration): SelectProps.Option => ({
  label: cfg.name,
  value: cfg.configurationId,
  description: `${cfg.type} - ${cfg.description}`,
  tags: [cfg.configurationId],
});

export const ConfigurationSelector: FunctionComponent<ConfigurationSelectorProps> = ({
  onConfigurationSelected,
  notifications,
  setNotifications,
  accessToken,
  configurationType,
}) => {
  const [fluxConfigurations, setFluxConfigurations] = useState<Configuration[]>([]);
  const [customConfigurations, setCustomConfigurations] = useState<Configuration[]>([]);
  const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchConfigurations = useCallback(
    async (token: string) => {
      setLoading(true);
      try {
        const [custom, flux] = await Promise.all([
          listConfigurations(token, configurationType !== "flux" ? configurationType : undefined),
          listConfigurations(token, "flux"),
        ]);

        // Filter configurations by type if specified
        const filteredCustom = configurationType
          ? custom.filter((config) => config.type === configurationType)
          : custom;

        const filteredFlux =
          configurationType && configurationType !== "flux"
            ? flux.filter((config) => config.type === configurationType)
            : flux;

        setCustomConfigurations(filteredCustom);
        setFluxConfigurations(filteredFlux);
      } catch (error) {
        createFluxNotification({
          header: "Failed to fetch configurations",
          content: error instanceof Error ? error.message : "Unknown error occurred",
          type: "error",
          id: "selector_fetch_configurations_error",
          setNotifications,
        });
      } finally {
        setLoading(false);
      }
    },
    [setNotifications, notifications, configurationType]
  );

  useEffect(() => {
    if (accessToken) {
      fetchConfigurations(accessToken);
    }
  }, [accessToken, fetchConfigurations]);

  const handleSelectChange = useCallback(
    (event: SelectProps.ChangeDetail) => {
      setSelectedOption(event.selectedOption);

      const allConfigurations = [...fluxConfigurations, ...customConfigurations];
      const selectedConfig = allConfigurations.find((config) => config.configurationId === event.selectedOption.value);

      onConfigurationSelected(selectedConfig);
    },
    [fluxConfigurations, customConfigurations, onConfigurationSelected]
  );

  // Hide empty groups in options
  const configOptions = [
    ...(fluxConfigurations.length > 0
      ? [
          {
            label: "Flux Configurations",
            options: fluxConfigurations.map(configurationToSelectOption),
          },
        ]
      : []),
    ...(customConfigurations.length > 0
      ? [
          {
            label: "Custom Configurations",
            options: customConfigurations.map(configurationToSelectOption),
          },
        ]
      : []),
  ];

  return (
    <Select
      selectedOption={selectedOption}
      onChange={(e) => handleSelectChange(e.detail)}
      options={configOptions}
      placeholder="Select configuration"
      selectedAriaLabel="Selected"
      filteringType="auto"
      loadingText="Loading configurations..."
      statusType={loading ? "loading" : "finished"}
      empty="No configurations found"
    />
  );
};
