import ContentLayout from "@cloudscape-design/components/content-layout";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";
import { useCognito } from "../auth/cognito";
import { FunctionComponent, useEffect, useReducer, useState } from "react";
import { AuthUser, signOut } from "aws-amplify/auth";
import { ConsoleRouteConfig } from "../common/routes";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Form from "@cloudscape-design/components/form";
import Flashbar, { FlashbarProps } from "@cloudscape-design/components/flashbar";

interface ProfileState {
  authUser: AuthUser | undefined;
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  passwordUpdateLoading: boolean;
  passwordUpdateError: string | null;
}

type ProfileAction =
  | { type: "SET_AUTH_USER"; payload: AuthUser | undefined }
  | { type: "SET_OLD_PASSWORD"; payload: string }
  | { type: "SET_NEW_PASSWORD"; payload: string }
  | { type: "SET_CONFIRM_PASSWORD"; payload: string }
  | { type: "SET_UPDATE_LOADING"; payload: boolean }
  | { type: "SET_UPDATE_ERROR"; payload: string | null }
  | { type: "RESET_PASSWORD_FIELDS" };

const profileReducer = (state: ProfileState, action: ProfileAction): ProfileState => {
  switch (action.type) {
    case "SET_AUTH_USER":
      return { ...state, authUser: action.payload };
    case "SET_OLD_PASSWORD":
      return { ...state, oldPassword: action.payload };
    case "SET_NEW_PASSWORD":
      return { ...state, newPassword: action.payload };
    case "SET_CONFIRM_PASSWORD":
      return { ...state, confirmNewPassword: action.payload };
    case "SET_UPDATE_LOADING":
      return { ...state, passwordUpdateLoading: action.payload };
    case "SET_UPDATE_ERROR":
      return { ...state, passwordUpdateError: action.payload };
    case "RESET_PASSWORD_FIELDS":
      return {
        ...state,
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      };
    default:
      return state;
  }
};

export const ProfileView: FunctionComponent = () => {
  const { cognitoLoading, authenticated, fetchCognitoUser, changeUserPassword } = useCognito();

  const initialState: ProfileState = {
    authUser: undefined,
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    passwordUpdateLoading: false,
    passwordUpdateError: null,
  };

  const [state, dispatch] = useReducer(profileReducer, initialState);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);

  const redirectToAuthIfNeeded = (): boolean => {
    if (!authenticated) {
      window.location.href = `#${ConsoleRouteConfig.auth.href}`;
      return true;
    }
    return false;
  };

  const handleSignOut = async (): Promise<void> => {
    try {
      await signOut();
      window.location.href = `#${ConsoleRouteConfig.auth.href}`;
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const validatePasswordFields = (): string | null => {
    if (!state.oldPassword) {
      return "Current password is required";
    }

    if (!state.newPassword) {
      return "New password is required";
    }

    if (state.newPassword !== state.confirmNewPassword) {
      return "New password and confirmation do not match";
    }

    return null;
  };

  const handlePasswordUpdate = async (): Promise<void> => {
    const validationError = validatePasswordFields();

    if (validationError) {
      dispatch({ type: "SET_UPDATE_ERROR", payload: validationError });
      return;
    }

    dispatch({ type: "SET_UPDATE_LOADING", payload: true });
    dispatch({ type: "SET_UPDATE_ERROR", payload: null });

    try {
      const success = await changeUserPassword(state.oldPassword, state.newPassword);

      if (success) {
        dispatch({ type: "RESET_PASSWORD_FIELDS" });
        alert("Password updated successfully");
      } else {
        throw new Error("Failed to update password");
      }
    } catch (error) {
      dispatch({
        type: "SET_UPDATE_ERROR",
        payload: error instanceof Error ? error.message : "Failed to update password",
      });
      console.error("Failed to update password", error);
    } finally {
      dispatch({ type: "SET_UPDATE_LOADING", payload: false });
    }
  };

  useEffect(() => {
    if (cognitoLoading) return;

    if (!redirectToAuthIfNeeded()) {
      fetchCognitoUser().then((user) => {
        dispatch({ type: "SET_AUTH_USER", payload: user });
      });
    }
  }, [cognitoLoading, authenticated]);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      notifications={<Flashbar items={notifications} />}
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Profile
        </Header>
      }
    >
      <SpaceBetween size="xl">
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="normal" onClick={handleSignOut}>
                    Sign Out
                  </Button>
                </SpaceBetween>
              }
            >
              General
            </Header>
          }
        >
          <KeyValuePairs
            columns={2}
            items={[
              { label: "Username", value: state.authUser?.username },
              { label: "Password", value: "*******" },
            ]}
          />
        </Container>

        <Container header={<Header variant="h2">Update Password</Header>}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Form
              variant="embedded"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="primary" loading={state.passwordUpdateLoading} onClick={handlePasswordUpdate}>
                    Submit
                  </Button>
                </SpaceBetween>
              }
              errorText={state.passwordUpdateError}
            >
              <SpaceBetween size="l">
                <FormField label="Old Password">
                  <Input
                    type="password"
                    value={state.oldPassword}
                    onChange={(event) =>
                      dispatch({
                        type: "SET_OLD_PASSWORD",
                        payload: event.detail.value,
                      })
                    }
                  />
                </FormField>
                <FormField label="New Password">
                  <Input
                    type="password"
                    value={state.newPassword}
                    onChange={(event) =>
                      dispatch({
                        type: "SET_NEW_PASSWORD",
                        payload: event.detail.value,
                      })
                    }
                  />
                </FormField>
                <FormField label="Confirm New Password" description="Please enter the new password again to confirm">
                  <Input
                    type="password"
                    value={state.confirmNewPassword}
                    onChange={(event) =>
                      dispatch({
                        type: "SET_CONFIRM_PASSWORD",
                        payload: event.detail.value,
                      })
                    }
                  />
                </FormField>
              </SpaceBetween>
            </Form>
          </form>
        </Container>
      </SpaceBetween>
    </ContentLayout>
  );
};
