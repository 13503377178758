import { FunctionComponent, useCallback, useMemo, useState } from "react";
import Table from "@cloudscape-design/components/table";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { ExcelModelMetadata } from "../../common/types/models";
import { renderShortUUID, convertISOTimestampToLocale, createFluxNotification } from "../../common/helpers";
import { FluxTableWidgetProps } from "../../common/standards";
import { useNavigate } from "react-router-dom";
import { ConsoleRouteConfig } from "../../common/routes";

interface ExcelModelsTableProps extends FluxTableWidgetProps<ExcelModelMetadata> {}

// Custom hook for managing table operations
const useTableOperations = (
  onItemsDelete?: (items: ExcelModelMetadata[]) => Promise<void>,
  setNotifications?: React.Dispatch<React.SetStateAction<any[]>>
) => {
  const [selectedItems, setSelectedItems] = useState<ExcelModelMetadata[]>([]);
  const [operationStates, setOperationStates] = useState({
    deleting: false,
  });

  const handleDelete = useCallback(async () => {
    if (!onItemsDelete || selectedItems.length === 0) return;

    setOperationStates((prev) => ({ ...prev, deleting: true }));
    try {
      await onItemsDelete(selectedItems);
      createFluxNotification({
        header: "Models deleted",
        content: `Successfully deleted ${selectedItems.length} model(s)`,
        type: "success",
        id: `delete_success_${Date.now()}`,
        setNotifications,
      });
      setSelectedItems([]); // Clear selection after successful delete
    } catch (error) {
      createFluxNotification({
        header: "Delete failed",
        content: error instanceof Error ? error.message : "Failed to delete models",
        type: "error",
        id: `delete_error_${Date.now()}`,
        setNotifications,
      });
    } finally {
      setOperationStates((prev) => ({ ...prev, deleting: false }));
    }
  }, [selectedItems, onItemsDelete, setNotifications]);

  return {
    selectedItems,
    setSelectedItems,
    operationStates,
    handleDelete,
  };
};

export const ExcelModelsTable: FunctionComponent<ExcelModelsTableProps> = ({
  items,
  loading,
  onItemsDelete,
  onRefresh,
  setNotifications,
}) => {
  const navigate = useNavigate();
  const { selectedItems, setSelectedItems, operationStates, handleDelete } = useTableOperations(
    onItemsDelete,
    setNotifications
  );

  const columnDefinitions = useMemo(
    () => [
      {
        id: "name",
        header: "Name",
        cell: (item: ExcelModelMetadata) => (
          <Link
            onFollow={() => {
              navigate(`${ConsoleRouteConfig.model.href}/${item.modelId}`);
            }}
          >
            {item.modelName || item.modelId}
          </Link>
        ),
        sortingField: "modelName",
      },
      {
        id: "modelId",
        header: "Model ID",
        cell: (item: ExcelModelMetadata) => item.modelId,
        sortingField: "modelId",
      },
      {
        id: "createdAt",
        header: "Created At",
        cell: (item: ExcelModelMetadata) => convertISOTimestampToLocale(item.createdAt) || "-",
        sortingField: "createdAt",
      },
    ],
    [navigate]
  );

  return (
    <Table
      header={
        <Header
          variant="h3"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                iconName="remove"
                loading={operationStates.deleting}
                onClick={handleDelete}
                disabled={selectedItems.length === 0 || loading}
                ariaLabel="Delete selected models"
              >
                Delete
              </Button>
              <Button
                iconName="refresh"
                variant="icon"
                loading={loading}
                onClick={onRefresh}
                ariaLabel="Refresh models"
              />
            </SpaceBetween>
          }
        >
          Available Models
        </Header>
      }
      items={items}
      columnDefinitions={columnDefinitions}
      loading={loading}
      loadingText="Loading models..."
      empty={
        <Box textAlign="center" color="inherit">
          <b>No models found</b>
          <Box variant="p" color="inherit">
            Upload an Excel model to get started
          </Box>
        </Box>
      }
      selectedItems={selectedItems}
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectionType="multi"
      trackBy="modelId"
      sortingDisabled
    />
  );
};
