import { FunctionComponent, useEffect, useState } from "react";
import { FluxTableWidgetProps } from "../../common/standards";
import { CaseContext } from "../../common/types/models";
import Table, { TableProps } from "@cloudscape-design/components/table";
import Link from "@cloudscape-design/components/link";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import { convertISOTimestampToLocale, renderShortUUID } from "../../common/helpers";
import { deleteCaseContext } from "../../api/caseContext";
import { ConsoleRouteConfig } from "../../common/routes";

export const CaseContextTable: FunctionComponent<FluxTableWidgetProps<CaseContext>> = ({
  items,
  loading,
  onRefresh,
  accessToken,
}) => {
  const [sortedItems, setSortedItems] = useState<CaseContext[]>([]);
  const [selectedItems, setSelectedItems] = useState<CaseContext[]>([]);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [currentSortColumn, setCurrentSortColumn] = useState<TableProps.SortingColumn<CaseContext>>({
    sortingField: "name",
  });

  useEffect(() => {
    setSortedItems([...items]);
  }, [items]);

  const onDeleteClick = () => {
    if (!accessToken) {
      // TODO: Show error message
      return;
    }
    setDeleting(true);
    Promise.all(
      selectedItems.map((item) => {
        return deleteCaseContext(accessToken, {
          contextId: item.contextId,
        });
      })
    )
      .catch((error) => {
        console.error("Failed to delete case contexts", error);
      })
      .finally(() => {
        setDeleting(false);
        setSelectedItems([]);
        onRefresh();
      });
  };

  return (
    <Table
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                iconName="refresh"
                loading={refreshing}
                onClick={() => {
                  setRefreshing(true);
                  onRefresh().finally(() => {
                    setRefreshing(false);
                  });
                }}
              />
              <Button disabled={selectedItems.length === 0} onClick={onDeleteClick} loading={deleting}>
                Delete
              </Button>
            </SpaceBetween>
          }
        >
          Case Contexts
        </Header>
      }
      loading={loading}
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No resources</b>
            <Button>Create resource</Button>
          </SpaceBetween>
        </Box>
      }
      sortingColumn={currentSortColumn}
      selectedItems={selectedItems}
      items={sortedItems}
      loadingText="Loading case contexts..."
      selectionType="multi"
      trackBy="contextId"
      onSelectionChange={(e) => {
        setSelectedItems(e.detail.selectedItems);
      }}
      onSortingChange={(e) => {
        console.log(e.detail.sortingColumn);
        if (e.detail.sortingColumn.sortingField === currentSortColumn?.sortingField) {
          setSortedItems([...sortedItems.reverse()]);
        } else if (e.detail.sortingColumn.sortingField) {
          const key = e.detail.sortingColumn.sortingField as keyof CaseContext;
          const arr = items.sort((a, b) => {
            if (a[key] < b[key]) {
              return -1;
            }
            if (a[key] > b[key]) {
              return 1;
            }
            return 0;
          });
          setSortedItems([...arr]);
          setCurrentSortColumn({
            sortingField: e.detail.sortingColumn.sortingField,
          });
        }
      }}
      columnDefinitions={[
        {
          id: "contextId",
          header: "Context ID",
          cell: (item) => (
            <Link
              onClick={() => {
                window.location.href = `#${ConsoleRouteConfig.case.href}/${item.contextId}`;
              }}
            >
              {renderShortUUID(item.contextId)}
            </Link>
          ),
        },
        {
          id: "name",
          header: "Name",
          cell: (item) => item.name,
          sortingField: "name",
        },
        {
          id: "description",
          header: "Description",
          cell: (item) => item.description,
        },
        {
          id: "status",
          header: "Status",
          cell: (item) => item.status,
          sortingField: "status",
        },
        {
          id: "updatedAt",
          header: "Updated At",
          cell: (item) => convertISOTimestampToLocale(item.updatedAt),
          sortingField: "updatedAt",
        },
      ]}
    />
  );
};
