import ContentLayout from "@cloudscape-design/components/content-layout";
import { useCognito } from "../auth/cognito";
import Flashbar, { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { useEffect, useState, useCallback } from "react";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";
import { ConsoleRouteConfig } from "../common/routes";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Form from "@cloudscape-design/components/form";
import Container from "@cloudscape-design/components/container";
import { Button, SpaceBetween } from "@cloudscape-design/components";
import { fetchAuthSession } from "aws-amplify/auth";
import { createFluxNotification } from "../common/helpers";

export const AuthView = () => {
  const { cognitoLoading, authenticated, authenticate } = useCognito();
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const showSuccessNotification = useCallback(
    (header: string, content: string, id: string = `success_${Date.now()}`) => {
      createFluxNotification({
        header,
        content,
        type: "success",
        id,
        setNotifications,
      });
    },
    []
  );

  const showErrorNotification = useCallback((header: string, content: string, id: string = `error_${Date.now()}`) => {
    createFluxNotification({
      header,
      content,
      type: "error",
      id,
      setNotifications,
    });
  }, []);

  const handleAuthentication = useCallback(async () => {
    if (!username || !password) {
      showErrorNotification("Authentication failed", "Username and password are required", "auth_missing_fields");
      return;
    }

    setIsAuthenticating(true);
    try {
      const isAuthenticated = await authenticate(username, password);

      if (isAuthenticated) {
        showSuccessNotification(
          "Authentication successful",
          "You have been successfully authenticated",
          "auth_success"
        );
        window.location.href = ConsoleRouteConfig.home.href;
      } else {
        showErrorNotification("Authentication failed", "Invalid username or password", "auth_failed");
      }
    } catch (error) {
      showErrorNotification(
        "Authentication error",
        error instanceof Error ? error.message : "An unknown error occurred during authentication",
        "auth_error"
      );
    } finally {
      setIsAuthenticating(false);
    }
  }, [username, password, authenticate, showSuccessNotification, showErrorNotification]);

  useEffect(() => {
    fetchAuthSession().then((session) => {
      if (session.tokens !== undefined) {
        window.location.href = ConsoleRouteConfig.home.href;
      }
    });
  }, [cognitoLoading, authenticated]);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      notifications={<Flashbar items={notifications} />}
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Authentication
        </Header>
      }
    >
      <Container>
        <Form
          actions={
            <Button
              variant="primary"
              onClick={handleAuthentication}
              loading={isAuthenticating}
              disabled={!username || !password || isAuthenticating}
            >
              Sign In
            </Button>
          }
        >
          <SpaceBetween size="m">
            <FormField label="Username">
              <Input
                value={username}
                onChange={(event) => setUsername(event.detail.value)}
                disabled={isAuthenticating}
              />
            </FormField>
            <FormField label="Password">
              <Input
                type="password"
                value={password}
                onChange={(event) => setPassword(event.detail.value)}
                disabled={isAuthenticating}
              />
            </FormField>
          </SpaceBetween>
        </Form>
      </Container>
    </ContentLayout>
  );
};
