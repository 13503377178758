import { useEffect, FunctionComponent, useState, useCallback } from "react";
import { useCognito } from "../auth/cognito";
import { ConsoleRouteConfig } from "../common/routes";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Link from "@cloudscape-design/components/link";
import { useNavigate, useParams } from "react-router-dom";
import Flashbar, { FlashbarProps } from "@cloudscape-design/components/flashbar";
import Container from "@cloudscape-design/components/container";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";
import Button from "@cloudscape-design/components/button";
import { KeyValuePairs } from "@cloudscape-design/components";
import * as XLSX from "xlsx";

import { buildExcelDataIndex, ExcelDataIndex } from "../common/excel";
import { CaseContextSelector } from "../component/misc/CaseContextSelector";
import { CaseContext, ExcelModelMetadata, QueryRecord } from "../common/types/models";
import { useQueryRecords } from "../function/useQueryRecord";
import { useProcessModelJobs } from "../function/useProcessModelJobs";
import { useExcelModels } from "../function/useExcelModels";
import { ExcelModelsTable } from "../component/model/ExcelModelsTable";
import { UploadModelForm } from "../component/model/UploadModelForm";
import { ProcessModelJobsTable } from "../component/model/ProcessModelJobsTable";
import { getExcelModel, startWorkflow } from "../api/reportGeneration";
import { createContextFromQueryRecords } from "../common/helpers";

// Custom hook for managing model state and templates
const useModelState = (accessToken: string | undefined, id: string | undefined, models: ExcelModelMetadata[]) => {
  const [currentModel, setCurrentModel] = useState<ExcelModelMetadata | undefined>(undefined);
  const [templates, setTemplates] = useState<Record<string, string> | undefined>(undefined);

  useEffect(() => {
    if (id) {
      const model = models.find((model) => model.modelId === id);
      setCurrentModel(model);
    }
  }, [id, models]);

  useEffect(() => {
    const fetchModelTemplates = async () => {
      if (currentModel && accessToken) {
        const response = await getExcelModel(accessToken, {
          modelId: currentModel.modelId,
        });
        setTemplates(response.templates);
      }
    };
    fetchModelTemplates();
  }, [currentModel, accessToken]);

  return { currentModel, templates };
};

// Custom hook for handling workflow operations
const useWorkflowOperations = (
  accessToken: string | undefined,
  currentModel: ExcelModelMetadata | undefined,
  templates: Record<string, string> | undefined,
  queryRecords: QueryRecord[] | undefined,
  setNotifications: React.Dispatch<React.SetStateAction<FlashbarProps.MessageDefinition[]>>
) => {
  const [startingWorkflow, setStartingWorkflow] = useState(false);

  const startProcessCaseWorkflow = useCallback(
    async (caseContext: CaseContext) => {
      if (!templates || !caseContext || !currentModel || !accessToken || !queryRecords) {
        return;
      }

      setStartingWorkflow(true);
      try {
        const context = createContextFromQueryRecords(queryRecords);
        const sheets: Record<string, ExcelDataIndex> = {};

        Object.keys(templates).forEach((sheetName) => {
          try {
            const worksheet = JSON.parse(templates[sheetName] || "{}") as XLSX.WorkSheet;
            const index = buildExcelDataIndex(worksheet);
            sheets[sheetName] = index;
          } catch (error) {
            throw new Error(
              `Failed to process sheet ${sheetName}: ${error instanceof Error ? error.message : "Unknown error"}`
            );
          }
        });

        const payload = {
          context,
          modelId: currentModel.modelId,
          sheets,
        };

        await startWorkflow(accessToken, {
          workflow: "ProcessExcelModel",
          parameters: JSON.stringify(payload),
        });

        setNotifications((prev) => [
          ...prev,
          {
            type: "success",
            content: "Model processing started successfully",
            id: `workflow-success-${Date.now()}`,
            dismissible: true,
          },
        ]);
      } catch (error) {
        setNotifications((prev) => [
          ...prev,
          {
            type: "error",
            content: `Failed to start model processing: ${error instanceof Error ? error.message : "Unknown error"}`,
            id: `workflow-error-${Date.now()}`,
            dismissible: true,
          },
        ]);
      } finally {
        setStartingWorkflow(false);
      }
    },
    [accessToken, currentModel, templates, queryRecords, setNotifications]
  );

  return { startingWorkflow, startProcessCaseWorkflow };
};

// Custom hook for file operations
const useFileOperations = (uploadModel: (name: string, sheets: Record<string, string>) => Promise<any>) => {
  const [uploading, setUploading] = useState(false);

  const handleModelUpload = useCallback(
    async (file: File, name: string) => {
      setUploading(true);
      const reader = new FileReader();

      try {
        const result = await new Promise<ArrayBuffer>((resolve, reject) => {
          reader.onload = (e) => resolve(e.target?.result as ArrayBuffer);
          reader.onerror = () => reject(new Error("Failed to read file"));
          reader.readAsArrayBuffer(file);
        });

        const workbook = XLSX.read(new Uint8Array(result), { type: "array" });
        const sheets: Record<string, string> = {};

        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          sheets[sheetName] = JSON.stringify(worksheet);
        });

        await uploadModel(name, sheets);
      } finally {
        setUploading(false);
      }
    },
    [uploadModel]
  );

  const handleModelDownload = useCallback(
    (currentModel: ExcelModelMetadata | undefined, templates: Record<string, string> | undefined) => {
      if (!templates || !currentModel) return;

      const workbook = XLSX.utils.book_new();
      Object.entries(templates).forEach(([sheetName, sheetData]) => {
        const worksheet: XLSX.WorkSheet = JSON.parse(sheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      });
      const fileName = `${currentModel.modelName || "model"}.xlsx`;
      XLSX.writeFile(workbook, fileName);
    },
    []
  );

  return { uploading, handleModelUpload, handleModelDownload };
};

export const ModelView: FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { cognitoLoading, authenticated, accessToken } = useCognito();
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [caseContext, setCaseContext] = useState<CaseContext | undefined>(undefined);

  // Custom hooks
  const { models, loading, fetchModels, uploadModel, batchRemoveModels } = useExcelModels(
    accessToken,
    setNotifications
  );

  const { currentModel, templates } = useModelState(accessToken, id, models);
  const { uploading, handleModelUpload, handleModelDownload } = useFileOperations(uploadModel);

  const { queryRecords } = useQueryRecords(accessToken, caseContext);

  const { startingWorkflow, startProcessCaseWorkflow } = useWorkflowOperations(
    accessToken,
    currentModel,
    templates,
    queryRecords || [], // Provide empty array as fallback
    setNotifications
  );

  const {
    jobs,
    loading: jobsLoading,
    refreshJobs,
    handleBatchDeleteJobs,
  } = useProcessModelJobs(accessToken, currentModel?.modelId || "", setNotifications);

  // Auth redirect effect
  useEffect(() => {
    if (cognitoLoading) return;
    if (!authenticated) {
      navigate(ConsoleRouteConfig.auth.href);
    }
  }, [cognitoLoading, authenticated, navigate]);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      notifications={<Flashbar items={notifications} />}
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Model
        </Header>
      }
    >
      <SpaceBetween size="xl">
        {!id && (
          <Container header={<Header variant="h2">Upload Model</Header>}>
            <UploadModelForm onUpload={handleModelUpload} uploading={uploading} />
          </Container>
        )}

        {!id && (
          <ExcelModelsTable
            items={models}
            loading={loading}
            onItemClicked={() => {}}
            onRefresh={fetchModels}
            onItemsDelete={async (items) => {
              await batchRemoveModels(items.map((model) => model.modelId));
            }}
          />
        )}

        {id && (
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="normal"
                      disabled={!currentModel || !templates}
                      onClick={() => handleModelDownload(currentModel, templates)}
                    >
                      Download
                    </Button>
                  </SpaceBetween>
                }
              >
                Model Overview
              </Header>
            }
          >
            <KeyValuePairs
              columns={3}
              items={[
                {
                  label: "Model ID",
                  value: id,
                },
                {
                  label: "Model Name",
                  value: currentModel?.modelName || "",
                },
                {
                  label: "Created At",
                  value: currentModel?.createdAt ? new Date(currentModel.createdAt).toLocaleString() : "",
                },
              ]}
            />
          </Container>
        )}

        {id && currentModel && (
          <Container header={<Header variant="h2">Process Case</Header>}>
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    loading={startingWorkflow}
                    disabled={!queryRecords || !templates || !caseContext}
                    onClick={async () => {
                      if (caseContext) {
                        await startProcessCaseWorkflow(caseContext);
                      }
                    }}
                  >
                    Start
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Case Context" description="Select a case context to process">
                  <CaseContextSelector
                    accessToken={accessToken}
                    onCaseSelected={setCaseContext}
                    setNotifications={setNotifications}
                  />
                </FormField>
              </SpaceBetween>
            </Form>
          </Container>
        )}

        {id && currentModel && (
          <ProcessModelJobsTable
            items={jobs}
            loading={jobsLoading}
            onRefresh={refreshJobs}
            onItemsDelete={async (items) => {
              await handleBatchDeleteJobs(items.map((item) => item.jobId || "").filter((item) => item !== ""));
            }}
            currentModel={currentModel}
            templates={templates}
            accessToken={accessToken}
            onItemClicked={() => {}}
          />
        )}
      </SpaceBetween>
    </ContentLayout>
  );
};
