import { AxiosError } from "axios";
import { CaseContext, QueryRecord } from "./types/models";
import { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { v4 as uuidv4 } from "uuid";

export const encodePdfFileBase64 = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const encodeBase64RemovePrefix = async (file: File) => {
  const res = (await encodePdfFileBase64(file)) as string;
  console.log(res.split(",")[0]);
  return res.split(",")[1];
};

export const renderErrorMessage = (error: AxiosError) => {
  const response_data = error.response?.data as any;
  const requestId = error.response?.headers["x-amzn-requestid"];
  if (response_data) {
    if (response_data.message) {
      return `${response_data.message} (Request ID: ${requestId})`;
    } else {
      return `${response_data} (Request ID: ${requestId})`;
    }
  }
  return `${error.message} (Request ID: ${requestId})`;
};

export const convertISOTimestampToLocale = (timestamp: string | undefined) => {
  if (!timestamp) return "-";
  return new Date(timestamp).toLocaleString();
};

export const convertCamelCaseToTitleCase = (camelCase: string) => {
  const camelCasePattern = /([a-z])([A-Z])/g;
  const titleCase = camelCase.replace(camelCasePattern, "$1 $2");
  return titleCase.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const range = (start: number, end: number) => {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

export const createFluxNotification = ({
  header,
  content,
  type,
  id,
  setNotifications,
}: {
  header: string;
  content: string;
  type: FlashbarProps.Type;
  setNotifications?: React.Dispatch<React.SetStateAction<FlashbarProps.MessageDefinition[]>>;
  id?: string;
}) => {
  if (setNotifications) {
    const uid = id ? id : uuidv4();
    setNotifications((prevNotifications) => [
      ...(prevNotifications || []),
      {
        header,
        content,
        type,
        dismissible: true,
        dismissLabel: "Dismiss",
        onDismiss: () => setNotifications((prev) => prev.filter((notification) => notification.id !== uid)),
        id: uid,
      },
    ]);
  }
};

export const renderShortUUID = (uuid: string) => {
  return uuid.split("-")[0];
};

export const createContextFromQueryRecords = (queryRecords: QueryRecord[]): Record<string, any> => {
  return queryRecords.reduce((acc, record) => {
    acc[record.queryId] = JSON.parse(record.content);
    return acc;
  }, {} as Record<string, any>);
};
