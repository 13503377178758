import { FunctionComponent, useEffect, useState, useCallback } from "react";
import { FluxTableWidgetProps } from "../../common/standards";
import Table from "@cloudscape-design/components/table";
import Link from "@cloudscape-design/components/link";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";
import FileUpload from "@cloudscape-design/components/file-upload";
import Input from "@cloudscape-design/components/input";
import Select from "@cloudscape-design/components/select";
import { updateCaseContext, uploadArtifact, removeArtifact } from "../../api/caseContext";
import { Container } from "@cloudscape-design/components";
import { CaseContext, ArtifactMetadata } from "../../common/types/models";
import { createFluxNotification, encodeBase64RemovePrefix, renderErrorMessage } from "../../common/helpers";

export interface ArtifactTableProps extends FluxTableWidgetProps<ArtifactMetadata> {
  caseContext?: CaseContext;
}

export const ArtifactTable: FunctionComponent<ArtifactTableProps> = ({
  items,
  onRefresh,
  accessToken,
  caseContext,
  loading,
  setNotifications,
  notifications,
}) => {
  // State management
  const [selectedItems, setSelectedItems] = useState<ArtifactMetadata[]>([]);
  const [artifactName, setArtifactName] = useState<string>("");
  const [artifactType, setArtifactType] = useState<string>("PDF");
  const [artifact, setArtifact] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [showUploadPanel, setShowUploadPanel] = useState<boolean>(false);

  const [removing, setRemoving] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  useEffect(() => {
    setSelectedItems([]);
  }, [items]);

  useEffect(() => {
    if (artifact.length > 0) {
      const file = artifact[0];

      const fileName = file.name;
      const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf(".")) || fileName;
      setArtifactName(nameWithoutExtension);

      const extension = fileName.split(".").pop()?.toUpperCase() || "";

      if (["PDF"].includes(extension)) {
        setArtifactType("PDF");
      } else if (["CSV"].includes(extension)) {
        setArtifactType("CSV");
      } else if (["XLSX", "XLS"].includes(extension)) {
        setArtifactType("XLSX");
      } else if (["JSON"].includes(extension)) {
        setArtifactType("JSON");
      }
    }
  }, [artifact]);

  const showErrorNotification = useCallback(
    (header: string, error: any) => {
      if (!setNotifications || !notifications) return;

      createFluxNotification({
        header,
        content: renderErrorMessage(error),
        type: "error",
        id: `error_${Date.now()}`,
        setNotifications,
      });
    },
    [notifications, setNotifications]
  );

  const handleRefresh = useCallback(async () => {
    if (!onRefresh) return;

    setRefreshing(true);
    try {
      await onRefresh();
    } catch (error) {
      showErrorNotification("Failed to refresh", error);
      console.error("Failed to refresh", error);
    } finally {
      setRefreshing(false);
    }
  }, [onRefresh, showErrorNotification]);

  const handleRemoveArtifact = useCallback(async () => {
    if (!accessToken || !caseContext || selectedItems.length === 0) return;
    setRemoving(true);
    try {
      const filteredArtifacts =
        caseContext.artifacts?.filter((artifactId) => !selectedItems.some((item) => item.artifactId === artifactId)) ||
        [];
      await updateCaseContext(accessToken, {
        caseContext: {
          ...caseContext,
          artifacts: filteredArtifacts,
        },
      });
      await Promise.all(
        selectedItems.map((item) => {
          if (!item.artifactId) return Promise.resolve();
          return removeArtifact(accessToken, { artifactId: item.artifactId });
        })
      );
      await handleRefresh();
    } catch (error) {
      showErrorNotification("Failed to remove artifact(s)", error);
      console.error("Failed to remove artifact(s) from case context", error);
    } finally {
      setRemoving(false);
    }
  }, [accessToken, caseContext, selectedItems, handleRefresh, showErrorNotification]);

  const handleUploadArtifact = useCallback(async () => {
    if (!accessToken || !caseContext || !artifact.length || !artifactName) return;

    setUploading(true);
    try {
      const encodedContent = await encodeBase64RemovePrefix(artifact[0]);

      const metadata = await uploadArtifact(accessToken, {
        content: encodedContent,
        metadata: {
          name: artifactName,
          type: artifactType,
        },
      });

      if (metadata.artifactId) {
        await updateCaseContext(accessToken, {
          caseContext: {
            ...caseContext,
            artifacts: [...(caseContext.artifacts || []), metadata.artifactId],
          },
        });
      }

      setArtifactName("");
      setArtifact([]);
      setShowUploadPanel(false);
      await handleRefresh();
    } catch (error) {
      showErrorNotification("Failed to upload artifact", error);
      console.error("Failed to upload artifact", error);
    } finally {
      setUploading(false);
    }
  }, [accessToken, caseContext, artifact, artifactName, artifactType, handleRefresh, showErrorNotification]);

  const toggleUploadPanel = useCallback(() => {
    setShowUploadPanel((current) => !current);
  }, []);

  // Column definitions for the table
  const columnDefinitions = [
    {
      id: "artifactId",
      header: "Artifact ID",
      cell: (item: ArtifactMetadata) => <Link onClick={() => {}}>{item.artifactId || "-"}</Link>,
      sortingField: "artifactId",
      isRowHeader: true,
    },
    {
      id: "name",
      header: "Artifact Name",
      cell: (item: ArtifactMetadata) => item.name || "-",
      sortingField: "name",
      editConfig: {
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Name Error",
        editingCell: (item: ArtifactMetadata, { currentValue, setValue }: any) => {
          return (
            <Input
              autoFocus={true}
              value={currentValue ?? item.name}
              onChange={(event) => setValue(event.detail.value)}
            />
          );
        },
      },
    },
    {
      id: "type",
      header: "Artifact Type",
      cell: (item: ArtifactMetadata) => item.type || "-",
      sortingField: "type",
    },
  ];

  // Artifact type options for the dropdown
  const artifactTypeOptions = [
    { label: "PDF", value: "PDF" },
    { label: "CSV", value: "CSV" },
    { label: "XLSX", value: "XLSX" },
    { label: "JSON", value: "JSON" },
  ];

  return (
    <Container>
      <SpaceBetween size="m">
        <Table
          columnDefinitions={columnDefinitions}
          submitEdit={(item, col, newValue) => {
            // TODO: implement
          }}
          enableKeyboardNavigation
          loading={loading}
          items={items}
          loadingText="Loading resources"
          sortingDisabled
          variant="embedded"
          selectionType="multi"
          trackBy="artifactId"
          selectedItems={selectedItems}
          onSelectionChange={(e) => {
            setSelectedItems(e.detail.selectedItems);
          }}
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No resources</b>
                <Button onClick={toggleUploadPanel} disabled={loading}>
                  Upload artifact
                </Button>
              </SpaceBetween>
            </Box>
          }
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                    onClick={handleRefresh}
                    iconName="refresh"
                    loading={refreshing}
                    disabled={!onRefresh || loading}
                  />
                  <Button
                    onClick={handleRemoveArtifact}
                    loading={removing}
                    disabled={selectedItems.length === 0 || !accessToken || !caseContext || loading}
                  >
                    Remove
                  </Button>
                  <Button onClick={toggleUploadPanel} disabled={loading}>
                    {showUploadPanel ? "Hide" : "Upload"}
                  </Button>
                </SpaceBetween>
              }
            >
              Artifacts
            </Header>
          }
        />
        {showUploadPanel && (
          <form onSubmit={(e) => e.preventDefault()}>
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button onClick={toggleUploadPanel} disabled={uploading}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    loading={uploading}
                    onClick={handleUploadArtifact}
                    disabled={!artifact.length || !artifactName || !accessToken || !caseContext || loading}
                  >
                    Upload
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween size={"s"}>
                <FormField label="New Artifact" description="Upload file from device">
                  <FileUpload
                    onChange={({ detail }) => setArtifact(detail.value)}
                    value={artifact}
                    i18nStrings={{
                      uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                      dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                      removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                      limitShowFewer: "Show fewer files",
                      limitShowMore: "Show more files",
                      errorIconAriaLabel: "Error",
                      warningIconAriaLabel: "Warning",
                    }}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    constraintText="Files under 3MB"
                  />
                </FormField>
                <FormField description="Readable name for your artifact" label="Artifact Name">
                  <Input
                    value={artifactName}
                    onChange={(event) => setArtifactName(event.detail.value)}
                    disabled={uploading}
                  />
                </FormField>
                <FormField label="Artifact Type">
                  <Select
                    options={artifactTypeOptions}
                    selectedOption={{
                      value: artifactType,
                      label: artifactType,
                    }}
                    onChange={(event) => setArtifactType(event.detail.selectedOption?.value ?? "")}
                    disabled={uploading}
                  />
                </FormField>
              </SpaceBetween>
            </Form>
          </form>
        )}
      </SpaceBetween>
    </Container>
  );
};
