import { FunctionComponent, useEffect, useState, useCallback } from "react";
import ace from "ace-builds";
import "ace-builds/css/ace.css";
import "ace-builds/css/theme/cloud_editor.css";
import "ace-builds/css/theme/cloud_editor_dark.css";
import "ace-builds/webpack-resolver";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import Form from "@cloudscape-design/components/form";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import CodeEditor from "@cloudscape-design/components/code-editor";
import { FluxWidgetProps } from "../../common/standards";
import { Configuration } from "../../common/types/models";
import { getConfiguration, updateConfiguration } from "../../api/configurations";
import Textarea from "@cloudscape-design/components/textarea";
import SegmentedControl from "@cloudscape-design/components/segmented-control";
import { CodeView } from "@cloudscape-design/code-view";
import { ConsoleRouteConfig } from "../../common/routes";
import { createFluxNotification, renderErrorMessage } from "../../common/helpers";

// CodeEditor i18n strings constant
const codeEditorI18nStrings = {
  loadingState: "Loading code editor",
  errorState: "There was an error loading the code editor.",
  errorStateRecovery: "Retry",

  editorGroupAriaLabel: "Code editor",
  statusBarGroupAriaLabel: "Status bar",

  cursorPosition: (row: number, column: number) => `Ln ${row}, Col ${column}`,
  errorsTab: "Errors",
  warningsTab: "Warnings",
  preferencesButtonAriaLabel: "Preferences",

  paneCloseButtonAriaLabel: "Close",

  preferencesModalHeader: "Preferences",
  preferencesModalCancel: "Cancel",
  preferencesModalConfirm: "Confirm",
  preferencesModalWrapLines: "Wrap lines",
  preferencesModalTheme: "Theme",
  preferencesModalLightThemes: "Light themes",
  preferencesModalDarkThemes: "Dark themes",
};

export interface ConfigurationEditorProps extends FluxWidgetProps {
  configurationId: string;
}

export const ConfigurationEditor: FunctionComponent<ConfigurationEditorProps> = ({
  configurationId,
  accessToken,
  setNotifications,
  notifications,
}) => {
  // State management
  const [config, setConfig] = useState<Configuration | undefined>(undefined);
  const [aceInstance, setAceInstance] = useState<any>();
  const [preferences, setPreferences] = useState({});
  const [editorLoading, setEditorLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("view");
  const [readonly, setReadonly] = useState(true);

  // Load Ace editor instance
  useEffect(() => {
    async function loadAce() {
      ace.config.set("useStrictCSP", true);
      return ace;
    }

    loadAce()
      .then((aceLib) => setAceInstance(aceLib))
      .catch((error) => {
        console.error("Failed to load Ace editor", error);
        showErrorNotification("Failed to load editor", error);
      })
      .finally(() => {
        setEditorLoading(false);
      });
  }, []);

  // Set readonly mode based on edit/view mode
  useEffect(() => {
    setReadonly(mode !== "edit");
  }, [mode]);

  // Error notification helper
  const showErrorNotification = useCallback(
    (header: string, error: any) => {
      if (!setNotifications || !notifications) return;

      createFluxNotification({
        header,
        content: renderErrorMessage(error),
        type: "error",
        id: `error_${Date.now()}`,
        setNotifications,
      });
    },
    [notifications, setNotifications]
  );

  // Fetch configuration data
  const fetchConfiguration = useCallback(async () => {
    if (!accessToken) return;

    setLoading(true);
    try {
      const configuration = await getConfiguration(accessToken, configurationId);
      setConfig(configuration);
    } catch (error) {
      console.error("Failed to fetch configuration", error);
      showErrorNotification("Failed to fetch configuration", error);
    } finally {
      setLoading(false);
    }
  }, [accessToken, configurationId, showErrorNotification]);

  useEffect(() => {
    fetchConfiguration();
  }, [fetchConfiguration]);

  // Update configuration handler
  const handleUpdate = useCallback(async () => {
    if (!config || !accessToken) return;

    setUpdating(true);
    try {
      // Format JSON content properly
      let formattedContent = config.content;
      try {
        formattedContent = JSON.stringify(JSON.parse(config.content), null, 2);
      } catch (parseError) {
        showErrorNotification("Invalid JSON format", parseError);
        return;
      }

      const updatedConfig = await updateConfiguration(accessToken, {
        ...config,
        content: formattedContent,
      });

      setConfig(updatedConfig);

      // Show success notification
      if (setNotifications && notifications) {
        createFluxNotification({
          header: "Configuration updated",
          content: "The configuration was successfully updated.",
          type: "success",
          id: `success_${Date.now()}`,
          setNotifications,
        });
      }
    } catch (error) {
      console.error("Failed to update configuration", error);
      showErrorNotification("Failed to update configuration", error);
    } finally {
      setUpdating(false);
    }
  }, [config, accessToken, showErrorNotification, notifications, setNotifications]);

  // Navigate back handler
  const handleNavigateBack = useCallback(() => {
    window.location.href = `#${ConsoleRouteConfig.configuration.href}`;
  }, []);

  // Update configuration field handler
  const handleFieldUpdate = useCallback(
    (field: keyof Configuration, value: string) => {
      if (!config) return;

      setConfig((prevConfig) => {
        if (!prevConfig) return prevConfig;
        return { ...prevConfig, [field]: value };
      });
    },
    [config]
  );

  // Handle mode change
  const handleModeChange = useCallback(({ detail }: { detail: { selectedId: string } }) => {
    setMode(detail.selectedId);
  }, []);

  // Editor mode options
  const modeOptions = [
    { text: "View", id: "view" },
    { text: "Edit", id: "edit" },
  ];

  // Editor themes
  const editorThemes = {
    light: ["cloud_editor"],
    dark: ["cloud_editor_dark"],
  };

  return (
    <Container
      variant="default"
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <SegmentedControl
                selectedId={mode}
                onChange={handleModeChange}
                label="Mode selector"
                options={modeOptions}
              />
            </SpaceBetween>
          }
        >
          {loading ? (
            <SpaceBetween direction="horizontal" size="xs">
              <span>Loading configuration...</span>
            </SpaceBetween>
          ) : (
            `ID: ${config?.configurationId || "Unknown"}`
          )}
        </Header>
      }
    >
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="normal" onClick={handleNavigateBack} disabled={updating}>
              Back
            </Button>
            <Button
              variant="primary"
              onClick={handleUpdate}
              loading={updating}
              disabled={!config || readonly || loading || editorLoading}
            >
              Update
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size="l">
          <FormField label="Name">
            <Input
              readOnly={readonly}
              value={config?.name || ""}
              onChange={(event) => handleFieldUpdate("name", event.detail.value)}
              placeholder="Configuration name"
              disabled={loading || updating || readonly}
            />
          </FormField>
          <FormField label="Type">
            <Input
              readOnly={readonly}
              value={config?.type || ""}
              onChange={(event) => handleFieldUpdate("type", event.detail.value)}
              placeholder="Configuration type"
              disabled={loading || updating || readonly}
            />
          </FormField>
          <FormField label="Description">
            <Textarea
              readOnly={readonly}
              value={config?.description || ""}
              onChange={(event) => handleFieldUpdate("description", event.detail.value)}
              placeholder="Enter a description for this configuration"
              disabled={loading || updating || readonly}
            />
          </FormField>
          <FormField label="Content" secondaryControl={loading && <span>Loading...</span>}>
            {readonly ? (
              <CodeView content={config?.content || "{}"} lineNumbers={true} />
            ) : (
              <CodeEditor
                ace={aceInstance}
                value={config?.content || "{}"}
                language="json"
                onDelayedChange={(event) => handleFieldUpdate("content", event.detail.value)}
                preferences={preferences}
                onPreferencesChange={(event) => setPreferences(event.detail)}
                loading={editorLoading || loading}
                i18nStrings={codeEditorI18nStrings}
                themes={editorThemes}
              />
            )}
          </FormField>
        </SpaceBetween>
      </Form>
    </Container>
  );
};
