import { FunctionComponent, useCallback, useMemo, useState } from "react";
import Table from "@cloudscape-design/components/table";
import Header from "@cloudscape-design/components/header";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import { ProcessModelJob, ExcelModelMetadata } from "../../common/types/models";
import { convertISOTimestampToLocale, createFluxNotification } from "../../common/helpers";
import { FluxTableWidgetProps } from "../../common/standards";
import * as XLSX from "xlsx";

interface ProcessModelJobsTableProps extends FluxTableWidgetProps<ProcessModelJob> {
  currentModel: ExcelModelMetadata;
  templates?: Record<string, string>;
}

// Custom hook for managing table operations
const useTableOperations = (
  onItemsDelete?: (items: ProcessModelJob[]) => Promise<void>,
  setNotifications?: React.Dispatch<React.SetStateAction<any[]>>
) => {
  const [selectedItems, setSelectedItems] = useState<ProcessModelJob[]>([]);
  const [operationStates, setOperationStates] = useState({
    deleting: false,
    downloading: false,
  });

  const handleDelete = useCallback(async () => {
    if (!onItemsDelete || selectedItems.length === 0) return;

    setOperationStates((prev) => ({ ...prev, deleting: true }));
    try {
      await onItemsDelete(selectedItems);
      createFluxNotification({
        header: "Jobs deleted",
        content: `Successfully deleted ${selectedItems.length} job(s)`,
        type: "success",
        id: `delete_success_${Date.now()}`,
        setNotifications,
      });
    } catch (error) {
      createFluxNotification({
        header: "Delete failed",
        content: error instanceof Error ? error.message : "Failed to delete jobs",
        type: "error",
        id: `delete_error_${Date.now()}`,
        setNotifications,
      });
    } finally {
      setOperationStates((prev) => ({ ...prev, deleting: false }));
    }
  }, [selectedItems, onItemsDelete, setNotifications]);

  return {
    selectedItems,
    setSelectedItems,
    operationStates,
    handleDelete,
  };
};

// Custom hook for handling Excel operations
const useExcelOperations = (
  selectedItems: ProcessModelJob[],
  templates?: Record<string, string>,
  setNotifications?: React.Dispatch<React.SetStateAction<any[]>>
) => {
  const handleJobsDownload = useCallback(() => {
    if (selectedItems.length === 0) return;

    try {
      const workbook = XLSX.utils.book_new();

      selectedItems.forEach((job, i) => {
        try {
          const sheetName = job.label || `Sheet ${i + 1}`;
          const worksheet = JSON.parse(job.content || "{}") as XLSX.WorkSheet;

          // Apply template if available
          const template = templates?.[sheetName];
          const finalWorksheet = template ? { ...JSON.parse(template), ...worksheet } : worksheet;

          XLSX.utils.book_append_sheet(workbook, finalWorksheet, sheetName);
        } catch (error) {
          createFluxNotification({
            header: "Sheet processing error",
            content: `Failed to process sheet ${job.label || i + 1}`,
            type: "error",
            id: `sheet_error_${Date.now()}_${i}`,
            setNotifications,
          });
        }
      });

      const fileName = `Processed_Models_${new Date().toISOString()}.xlsx`;
      XLSX.writeFile(workbook, fileName);

      createFluxNotification({
        header: "Download complete",
        content: `Successfully downloaded ${selectedItems.length} job(s)`,
        type: "success",
        id: `download_success_${Date.now()}`,
        setNotifications,
      });
    } catch (error) {
      createFluxNotification({
        header: "Download failed",
        content: error instanceof Error ? error.message : "Failed to download jobs",
        type: "error",
        id: `download_error_${Date.now()}`,
        setNotifications,
      });
    }
  }, [selectedItems, templates, setNotifications]);

  return { handleJobsDownload };
};

export const ProcessModelJobsTable: FunctionComponent<ProcessModelJobsTableProps> = ({
  items,
  templates,
  loading,
  onRefresh,
  onItemsDelete,
  setNotifications,
}) => {
  const { selectedItems, setSelectedItems, operationStates, handleDelete } = useTableOperations(
    onItemsDelete,
    setNotifications
  );

  const { handleJobsDownload } = useExcelOperations(selectedItems, templates, setNotifications);

  const getStatusIndicator = useCallback((status: string) => {
    switch (status.toUpperCase()) {
      case "IN_PROGRESS":
        return <StatusIndicator type="in-progress">In Progress</StatusIndicator>;
      case "COMPLETED":
        return <StatusIndicator type="success">Completed</StatusIndicator>;
      case "FAILED":
        return <StatusIndicator type="error">Failed</StatusIndicator>;
      default:
        return <StatusIndicator type="pending">{status}</StatusIndicator>;
    }
  }, []);

  const columnDefinitions = useMemo(
    () => [
      {
        id: "jobId",
        header: "Job ID",
        cell: (item: ProcessModelJob) => item.jobId,
        sortingField: "jobId",
      },
      {
        id: "label",
        header: "Label",
        cell: (item: ProcessModelJob) => item.label || "-",
        sortingField: "label",
      },
      {
        id: "status",
        header: "Status",
        cell: (item: ProcessModelJob) => getStatusIndicator(item.status || "Unknown"),
        sortingField: "status",
      },
      {
        id: "requestedBy",
        header: "Requested By",
        cell: (item: ProcessModelJob) => item.requestedBy || "-",
        sortingField: "requestedBy",
      },
      {
        id: "createdAt",
        header: "Created At",
        cell: (item: ProcessModelJob) => convertISOTimestampToLocale(item.createdAt) || "-",
        sortingField: "createdAt",
      },
      {
        id: "updatedAt",
        header: "Updated At",
        cell: (item: ProcessModelJob) => convertISOTimestampToLocale(item.updatedAt) || "-",
        sortingField: "updatedAt",
      },
    ],
    [getStatusIndicator]
  );

  return (
    <Table
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                iconName="remove"
                variant="normal"
                onClick={handleDelete}
                loading={operationStates.deleting}
                disabled={selectedItems.length === 0 || loading}
                ariaLabel="Delete selected jobs"
              >
                Delete
              </Button>
              <Button
                iconName="download"
                variant="normal"
                onClick={handleJobsDownload}
                disabled={selectedItems.length === 0 || loading || !templates}
                ariaLabel="Download selected jobs"
              >
                Download
              </Button>
              <Button
                iconName="refresh"
                variant="icon"
                loading={loading}
                onClick={onRefresh}
                ariaLabel="Refresh jobs"
              />
            </SpaceBetween>
          }
        >
          Past Jobs
        </Header>
      }
      items={items}
      columnDefinitions={columnDefinitions}
      loading={loading}
      loadingText="Loading jobs..."
      empty={
        <Box textAlign="center" color="inherit">
          <b>No jobs found</b>
          <Box variant="p" color="inherit">
            Process a model to create jobs
          </Box>
        </Box>
      }
      selectedItems={selectedItems}
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectionType="multi"
      trackBy="jobId"
      sortingDisabled
    />
  );
};
