import { FunctionComponent, useState, useCallback } from "react";
import { FluxTableWidgetProps } from "../../common/standards";
import Table from "@cloudscape-design/components/table";
import Link from "@cloudscape-design/components/link";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import { Report } from "../../common/types/models";
import { convertISOTimestampToLocale, createFluxNotification, renderErrorMessage } from "../../common/helpers";
import { ConsoleRouteConfig } from "../../common/routes";
import { deleteReport } from "../../api/reportGeneration";

export interface ReportTableProps extends FluxTableWidgetProps<Report> {}

export const ReportTable: FunctionComponent<ReportTableProps> = ({
  items,
  onRefresh,
  accessToken,
  loading,
  setNotifications,
}) => {
  const [selectedItems, setSelectedItems] = useState<Report[]>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const handleRefresh = useCallback(async () => {
    if (!onRefresh) return;

    setRefreshing(true);
    try {
      await onRefresh();
    } catch (error: any) {
      if (setNotifications) {
        createFluxNotification({
          header: "Failed to refresh reports",
          content: renderErrorMessage(error),
          type: "error",
          id: `refresh_error_${Date.now()}`,
          setNotifications,
        });
      }
    } finally {
      setSelectedItems([]);
      setRefreshing(false);
    }
  }, [onRefresh, setNotifications]);

  const handleDeleteReport = useCallback(async () => {
    if (!accessToken || selectedItems.length === 0 || !setNotifications) return;

    setDeleting(true);
    try {
      await Promise.all(
        selectedItems.map((item) => {
          if (!item.reportId) return Promise.resolve();
          return deleteReport(accessToken, {
            reportId: item.reportId,
          });
        })
      );
      createFluxNotification({
        header: "Report deleted",
        content:
          selectedItems.length > 1
            ? `${selectedItems.length} reports were successfully deleted.`
            : "Report was successfully deleted.",
        type: "success",
        id: `delete_success_${Date.now()}`,
        setNotifications,
      });

      // Refresh the list
      await handleRefresh();
    } catch (error: any) {
      createFluxNotification({
        header: "Failed to delete report(s)",
        content: renderErrorMessage(error),
        type: "error",
        id: `delete_error_${Date.now()}`,
        setNotifications,
      });
    } finally {
      setDeleting(false);
    }
  }, [accessToken, selectedItems, handleRefresh, setNotifications]);

  return (
    <Table
      columnDefinitions={[
        {
          id: "reportId",
          header: "Report ID",
          cell: (item) => <Link href={`#${ConsoleRouteConfig.report.href}/${item.reportId}`}>{item.reportId}</Link>,
          sortingField: "reportId",
          isRowHeader: true,
        },
        {
          id: "name",
          header: "Name",
          cell: (item) => item.metadata?.name || "-",
          sortingField: "name",
        },
        {
          id: "status",
          header: "Status",
          cell: (item) => item.status || "-",
          sortingField: "status",
        },
        {
          id: "createdAt",
          header: "Created At",
          cell: (item) => convertISOTimestampToLocale(item.metadata?.createdAt) || "-",
          sortingField: "createdAt",
        },
        {
          id: "updatedAt",
          header: "Updated At",
          cell: (item) => convertISOTimestampToLocale(item.metadata?.updatedAt) || "-",
          sortingField: "updatedAt",
        },
      ]}
      loading={loading || refreshing || deleting}
      loadingText={deleting ? "Deleting reports" : "Loading reports"}
      items={items}
      selectionType="multi"
      selectedItems={selectedItems}
      onSelectionChange={(e) => setSelectedItems(e.detail.selectedItems)}
      trackBy="reportId"
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No reports</b>
          </SpaceBetween>
        </Box>
      }
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween size="xs" direction="horizontal">
              <Button
                onClick={handleRefresh}
                iconName="refresh"
                loading={refreshing}
                disabled={onRefresh === undefined || loading || deleting}
              />
              <Button
                onClick={handleDeleteReport}
                loading={deleting}
                disabled={selectedItems.length === 0 || !accessToken || loading || refreshing}
              >
                Delete
              </Button>
            </SpaceBetween>
          }
        >
          Reports
        </Header>
      }
    />
  );
};
