import { FunctionComponent, useEffect, useState, useCallback, useMemo } from "react";
import { FluxWidgetProps } from "../../common/standards";
import { ArtifactMetadata, CaseContext, QueryRecord } from "../../common/types/models";
import {
  getCaseContext,
  listQueryRecords,
  updateCaseContext,
  updateQueryRecord,
  uploadArtifact,
  getArtifactMetadata,
} from "../../api/caseContext";
import { createFluxNotification, encodeBase64RemovePrefix } from "../../common/helpers";
import { SourceType } from "../../common/enums";
import { SourceTable } from "../table/SourceTable";
import { QueryRecordTable } from "../table/QueryRecordTable";
import { QueryRecordView } from "./QueryRecordView";
import { ArtifactTable } from "../table/ArtifactTable";
import { CaseInfoView } from "./CaseInfoView";
import Container from "@cloudscape-design/components/container";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Spinner from "@cloudscape-design/components/spinner";
import Header from "@cloudscape-design/components/header";
import { useQueryRecords } from "../../function/useQueryRecord";

export interface CaseOverviewProps extends FluxWidgetProps {
  contextId: string;
  onChange: (caseContext: CaseContext) => void;
}

// Custom hook for fetching artifact metadata
const useArtifactMetadata = (ids: string[] | undefined, accessToken: string | undefined) => {
  const [metadataItems, setMetadataItems] = useState<ArtifactMetadata[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMetadata = async () => {
      if (!accessToken) return;
      if (!ids || ids.length === 0) {
        setMetadataItems([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const metadataList: ArtifactMetadata[] = [];
        for (const id of ids) {
          const metadata = await getArtifactMetadata(accessToken, id);
          metadataList.push(metadata);
        }
        setMetadataItems(metadataList);
      } catch (error) {
        console.error("Failed to fetch artifact metadata:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetadata();
  }, [ids, accessToken]);

  return { metadataItems, loading };
};

// Custom hook for case context management
const useCaseContext = (contextId: string, accessToken: string | undefined) => {
  const [caseContext, setCaseContext] = useState<CaseContext | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchCaseContext = useCallback(async () => {
    if (!contextId || !accessToken) return;

    setLoading(true);
    try {
      const result = await getCaseContext(accessToken, { contextId });
      setCaseContext(result);
    } catch (error) {
      console.error("Failed to fetch case context:", error);
    } finally {
      setLoading(false);
    }
  }, [contextId, accessToken]);

  useEffect(() => {
    fetchCaseContext();
  }, [fetchCaseContext]);

  return { caseContext, setCaseContext, loading, refreshCaseContext: fetchCaseContext };
};

export const CaseOverview: FunctionComponent<CaseOverviewProps> = ({
  contextId,
  onChange,
  setNotifications,
  notifications,
  accessToken,
}) => {
  // Use custom hooks for data management
  const {
    caseContext,
    setCaseContext,
    loading: caseContextLoading,
    refreshCaseContext,
  } = useCaseContext(contextId, accessToken);

  const {
    queryRecords,
    loading: queryRecordsLoading,
    refreshQueryRecords,
    currentQueryRecord,
    setCurrentQueryRecord,
  } = useQueryRecords(accessToken, caseContext);

  // Fetch metadata for sources and artifacts
  const { metadataItems: sourceMetadataItems, loading: sourceMetadataLoading } = useArtifactMetadata(
    caseContext?.sources,
    accessToken
  );

  const { metadataItems: artifactMetadataItems, loading: artifactMetadataLoading } = useArtifactMetadata(
    caseContext?.artifacts,
    accessToken
  );

  useEffect(() => {
    if (caseContext) {
      refreshQueryRecords();
    }
  }, [caseContext, refreshQueryRecords]);

  // Handle source upload in a pure way
  const handleSourceUpload = useCallback(
    async (source: File, documentName: string, type: SourceType) => {
      if (!accessToken || !caseContext) return;

      try {
        const content = await encodeBase64RemovePrefix(source);
        const metadata = await uploadArtifact(accessToken, {
          content,
          metadata: {
            name: documentName,
            type: type,
            format: "PDF",
          },
        });

        if (metadata.artifactId) {
          const updatedCaseContext = await updateCaseContext(accessToken, {
            caseContext: {
              ...caseContext,
              sources: [...caseContext.sources, metadata.artifactId],
            },
          });
          onChange(updatedCaseContext);
          setCaseContext(updatedCaseContext);
        }
      } catch (error) {
        console.error("Failed to upload source:", error);
        createFluxNotification({
          header: "Upload Failed",
          content: "There was an error uploading the source document.",
          type: "error",
          id: "source_upload_failed",
          setNotifications,
        });
      }
    },
    [accessToken, caseContext, onChange, setCaseContext, setNotifications, notifications]
  );

  const handleQueryRecordUpdate = useCallback(
    async (record: QueryRecord) => {
      if (!accessToken) {
        createFluxNotification({
          header: "Failed to update record",
          content: "Failed to update record. Refresh the page and try again.",
          type: "error",
          id: "update_record_failed_session_expired",
          setNotifications,
        });
        return;
      }

      try {
        const result = await updateQueryRecord(accessToken, record);
        await refreshQueryRecords();
        return result;
      } catch (error) {
        console.error("Failed to update query record:", error);
        createFluxNotification({
          header: "Update Failed",
          content: "There was an error updating the query record.",
          type: "error",
          id: "query_record_update_failed",
          setNotifications,
        });
      }
    },
    [accessToken, refreshQueryRecords, setNotifications, notifications]
  );

  if (!caseContext) return null;

  if (caseContextLoading)
    return (
      <Container>
        <Box textAlign="center" padding={{ top: "l", bottom: "l" }}>
          <SpaceBetween size="s" direction="vertical" alignItems="center">
            <Spinner size="large" variant="normal" />
            <Box variant="p" color="text-status-info">
              Loading report details...
            </Box>
          </SpaceBetween>
        </Box>
      </Container>
    );

  return (
    <SpaceBetween size="xl">
      <Container header={<Header variant="h2">Overview</Header>}>
        <CaseInfoView caseContext={caseContext} />
      </Container>

      <SourceTable
        caseContext={caseContext}
        onSourceUpload={handleSourceUpload}
        loading={sourceMetadataLoading}
        onItemClicked={() => {}} // No-op function
        items={sourceMetadataItems}
        onRefresh={refreshCaseContext}
        accessToken={accessToken}
        notifications={notifications}
        setNotifications={setNotifications}
      />

      <ArtifactTable
        caseContext={caseContext}
        onRefresh={refreshCaseContext}
        onItemClicked={() => {}} // No-op function
        accessToken={accessToken}
        loading={artifactMetadataLoading}
        items={artifactMetadataItems}
      />

      <QueryRecordTable
        items={queryRecords}
        loading={queryRecordsLoading}
        onRefresh={refreshQueryRecords}
        onItemClicked={setCurrentQueryRecord}
        accessToken={accessToken}
      />

      <QueryRecordView
        caseContext={caseContext}
        accessToken={accessToken}
        record={currentQueryRecord}
        onChange={handleQueryRecordUpdate}
        notifications={notifications}
        setNotifications={setNotifications}
      />
    </SpaceBetween>
  );
};
