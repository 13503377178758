import { useState, useEffect } from "react";
import { ProcessModelJob } from "../common/types/models";
import { listProcessModelJobs, deleteProcessModelJob } from "../api/reportGeneration";
import { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { createFluxNotification } from "../common/helpers";

export const useProcessModelJobs = (
  accessToken: string | undefined,
  modelId: string,
  setNotifications?: React.Dispatch<React.SetStateAction<FlashbarProps.MessageDefinition[]>>
) => {
  const [jobs, setJobs] = useState<ProcessModelJob[]>([]);
  const [loading, setLoading] = useState(false);

  const refreshJobs = async () => {
    if (!accessToken) return;
    if (!modelId || modelId === "") return;

    setLoading(true);
    try {
      const jobsList = await listProcessModelJobs(accessToken, modelId);
      setJobs(jobsList);
    } catch (error) {
      console.error("Error fetching process model jobs:", error);
      createFluxNotification({
        id: `process-model-jobs-error-${Date.now()}`,
        type: "error",
        header: "Failed to fetch process model jobs",
        content: error instanceof Error ? error.message : "Unknown error",
        setNotifications,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshJobs();
  }, [accessToken, modelId, setNotifications]);

  const handleDeleteJob = async (jobId: string) => {
    if (!accessToken) return;

    setLoading(true);
    try {
      await deleteProcessModelJob(accessToken, modelId, jobId);
      createFluxNotification({
        id: `delete-job-success-${Date.now()}`,
        type: "success",
        header: "Job deleted successfully",
        content: `The process model job has been deleted.`,
        setNotifications,
      });
      refreshJobs();
    } catch (error) {
      console.error("Error deleting process model job:", error);
      createFluxNotification({
        id: `delete-job-error-${Date.now()}`,
        type: "error",
        header: "Failed to delete process model job",
        content: error instanceof Error ? error.message : "Unknown error",
        setNotifications,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBatchDeleteJobs = async (selectedJobIds: string[]) => {
    if (!accessToken || selectedJobIds.length === 0) return;

    setLoading(true);
    try {
      await Promise.all(selectedJobIds.map((jobId) => deleteProcessModelJob(accessToken, modelId, jobId)));

      createFluxNotification({
        id: `delete-jobs-success-${Date.now()}`,
        type: "success",
        header: "Jobs deleted successfully",
        content: `${selectedJobIds.length} process model job(s) have been deleted.`,
        setNotifications,
      });
      refreshJobs();
    } catch (error) {
      console.error("Error deleting selected process model jobs:", error);
      createFluxNotification({
        id: `delete-jobs-error-${Date.now()}`,
        type: "error",
        header: "Failed to delete selected jobs",
        content: error instanceof Error ? error.message : "Unknown error",
        setNotifications,
      });
    } finally {
      setLoading(false);
    }
  };

  return { jobs, loading, refreshJobs, handleDeleteJob, handleBatchDeleteJobs };
};
