import * as XLSX from "xlsx";

export interface ExcelDataIndex {
  [cell: string]: XLSX.CellObject | { start: number; end: number } | undefined; // Use undefined for empty cells
  _rowBounds: { start: number; end: number };
  _colBounds: { start: number; end: number };
}

export const buildExcelDataIndex = (worksheet: XLSX.WorkSheet): ExcelDataIndex => {
  // Initialize the index
  const index: ExcelDataIndex = {
    _rowBounds: { start: Infinity, end: -Infinity },
    _colBounds: { start: Infinity, end: -Infinity },
  };

  // Find all populated cells and determine boundaries
  Object.keys(worksheet).forEach((key) => {
    if (key[0] === "!") return; // Skip special keys like !ref

    const match = key.match(/([A-Z]+)(\d+)/);
    if (!match) return;

    const colLetter = match[1];
    const row = parseInt(match[2]);
    const colNum = XLSX.utils.decode_col(colLetter);

    // Update boundaries
    index._rowBounds.start = Math.min(index._rowBounds.start, row);
    index._rowBounds.end = Math.max(index._rowBounds.end, row);
    index._colBounds.start = Math.min(index._colBounds.start, colNum);
    index._colBounds.end = Math.max(index._colBounds.end, colNum);
  });

  // Adjust bounds if no cells found
  if (index._rowBounds.start === Infinity) {
    index._rowBounds = { start: 0, end: 0 };
    index._colBounds = { start: 0, end: 0 };
    return index;
  }

  // Remove leading/trailing empty rows
  let actualRowStart = index._rowBounds.start;
  let actualRowEnd = index._rowBounds.end;

  // Check leading rows for emptiness
  rowLoop: for (let r = index._rowBounds.start; r <= index._rowBounds.end; r++) {
    for (let c = index._colBounds.start; c <= index._colBounds.end; c++) {
      const cellAddress = XLSX.utils.encode_cell({ r: r - 1, c });
      if (worksheet[cellAddress]) {
        actualRowStart = r;
        break rowLoop;
      }
    }
  }

  // Check trailing rows for emptiness
  rowLoop: for (let r = index._rowBounds.end; r >= actualRowStart; r--) {
    for (let c = index._colBounds.start; c <= index._colBounds.end; c++) {
      const cellAddress = XLSX.utils.encode_cell({ r: r - 1, c });
      if (worksheet[cellAddress]) {
        actualRowEnd = r;
        break rowLoop;
      }
    }
  }

  // Remove leading/trailing empty columns
  let actualColStart = index._colBounds.start;
  let actualColEnd = index._colBounds.end;

  // Check leading columns for emptiness
  colLoop: for (let c = index._colBounds.start; c <= index._colBounds.end; c++) {
    for (let r = actualRowStart; r <= actualRowEnd; r++) {
      const cellAddress = XLSX.utils.encode_cell({ r: r - 1, c });
      if (worksheet[cellAddress]) {
        actualColStart = c;
        break colLoop;
      }
    }
  }

  // Check trailing columns for emptiness
  colLoop: for (let c = index._colBounds.end; c >= actualColStart; c--) {
    for (let r = actualRowStart; r <= actualRowEnd; r++) {
      const cellAddress = XLSX.utils.encode_cell({ r: r - 1, c });
      if (worksheet[cellAddress]) {
        actualColEnd = c;
        break colLoop;
      }
    }
  }

  // Update bounds with cleaned up values
  index._rowBounds = { start: actualRowStart, end: actualRowEnd };
  index._colBounds = { start: actualColStart, end: actualColEnd };

  // Populate the index with cell data from the cleaned region
  for (let r = actualRowStart; r <= actualRowEnd; r++) {
    for (let c = actualColStart; c <= actualColEnd; c++) {
      const cellAddress = XLSX.utils.encode_cell({ r: r - 1, c });
      const cell = worksheet[cellAddress];

      if (cell) {
        index[cellAddress] = cell;
      } else {
        index[cellAddress] = undefined;
      }
    }
  }

  return index;
};
