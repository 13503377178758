import React, { FunctionComponent, useState } from "react";
import {
  Box,
  Container,
  Header,
  SpaceBetween,
  Tabs,
  StatusIndicator,
  Grid,
  ColumnLayout,
  Badge,
  Button,
  Icon,
  ExpandableSection,
} from "@cloudscape-design/components";
import { BlockDefinition, Report } from "../../common/types/models";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { FluxWidgetProps } from "../../common/standards";
import { convertISOTimestampToLocale } from "../../common/helpers";
import styles from "./markdown.module.css";

export interface ReportBlock {
  reportId: string;
  blockId: string;
  status: string;
  title?: string;
  content?: string;
  definition?: BlockDefinition;
  createdAt: string;
  updatedAt: string;
}

interface ReportDetailProps extends FluxWidgetProps {
  report: Report;
  blocks: ReportBlock[];
}

const renderStatusIndicator = (status: string) => {
  switch (status) {
    case "COMPLETED":
      return <StatusIndicator type="success">Completed</StatusIndicator>;
    case "IN_PROGRESS":
      return <StatusIndicator type="in-progress">In Progress</StatusIndicator>;
    case "FAILED":
      return <StatusIndicator type="error">Failed</StatusIndicator>;
    default:
      return <StatusIndicator type="pending">{status}</StatusIndicator>;
  }
};

const ReportMetadata: FunctionComponent<{ report: Report }> = ({ report }) => {
  return (
    <Container header={<Header variant="h2">Report Details</Header>}>
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Report ID</Box>
            <div>{report.reportId}</div>
          </div>

          <div>
            <Box variant="awsui-key-label">Status</Box>
            <div>{renderStatusIndicator(report.status)}</div>
          </div>

          <div>
            <Box variant="awsui-key-label">Created By</Box>
            <div>{report.metadata?.createdBy || "N/A"}</div>
          </div>
        </SpaceBetween>

        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Created At</Box>
            <div>{convertISOTimestampToLocale(report.metadata?.createdAt)}</div>
          </div>

          <div>
            <Box variant="awsui-key-label">Updated At</Box>
            <div>{convertISOTimestampToLocale(report.metadata?.updatedAt)}</div>
          </div>

          <div>
            <Box variant="awsui-key-label">Current Wave</Box>
            <div>{report.currentWave || "N/A"}</div>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

const BlockDependencyList: FunctionComponent<{ block: BlockDefinition; allBlocks: BlockDefinition[] }> = ({
  block,
  allBlocks,
}) => {
  if (!block.references || block.references.length === 0) {
    return <div>No dependencies</div>;
  }

  return (
    <ul>
      {block.references.map((reference, index) => {
        const referencedBlock = allBlocks.find((b) => b.blockId === reference.blockId);
        return (
          <li key={index}>
            {referencedBlock?.title || reference.blockId}
            {reference.remark && (
              <span>
                {" "}
                <i>{reference.remark}</i>
              </span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const BlockContent: FunctionComponent<{ content: string }> = ({ content }) => {
  return (
    <Box padding="xs">
      <div className={styles.markdownContent}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
      </div>
    </Box>
  );
};

const BlockView: FunctionComponent<{ block: ReportBlock }> = ({ block }) => {
  return (
    <Container>
      <BlockContent content={block.content || ""} />
    </Container>
  );
};

interface BlockStructureViewProps {
  block: ReportBlock;
  allBlocks: BlockDefinition[];
}

const BlockStructureView: FunctionComponent<BlockStructureViewProps> = ({ block, allBlocks }) => {
  if (!block.definition) return null;
  const hasDependencies = block.definition.references && block.definition.references.length > 0;

  return (
    <Container
      header={
        <Header variant="h3">
          {block.definition.title}
          <span style={{ marginLeft: "10px" }}>{renderStatusIndicator(block.status)}</span>
        </Header>
      }
    >
      <SpaceBetween size="l">
        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          <SpaceBetween size="s">
            <div>
              <Box variant="awsui-key-label">Block ID</Box>
              <div>{block.blockId}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Created At</Box>
              <div>{convertISOTimestampToLocale(block.createdAt)}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Updated At</Box>
              <div>{convertISOTimestampToLocale(block.updatedAt)}</div>
            </div>
          </SpaceBetween>
          <SpaceBetween size="s">
            {hasDependencies && (
              <div>
                <Box variant="awsui-key-label">Dependencies</Box>
                <BlockDependencyList block={block.definition} allBlocks={allBlocks} />
              </div>
            )}
            <div>
              <Box variant="awsui-key-label">Instruction</Box>
              <div>{block.definition.instruction || "No instruction provided"}</div>
            </div>
          </SpaceBetween>
        </Grid>
      </SpaceBetween>
    </Container>
  );
};

const organizeBlocksByWaves = (report: Report, blocks: ReportBlock[]) => {
  const orderedBlocks: ReportBlock[] = [];

  report.definition?.blocks.forEach((blockDefinition) => {
    const block = blocks.find((b) => b.blockId === blockDefinition.blockId);
    if (block) {
      orderedBlocks.push(block);
    }
  });

  return orderedBlocks;
};

export const ReportDetailView: FunctionComponent<ReportDetailProps> = ({ report, blocks }) => {
  const [downloading, setDownloading] = useState(false);
  const orderedBlocks = organizeBlocksByWaves(report, blocks);
  const allDefinitions = report.definition?.blocks || [];

  return (
    <SpaceBetween size="l">
      <Container
        header={
          <Header
            variant="h1"
            description={report.metadata?.description}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button iconName="download" loading={downloading} onClick={() => {}}>
                  Download Report
                </Button>
                <Button variant="primary">Share</Button>
              </SpaceBetween>
            }
          >
            {report.metadata?.name || "Report"}
          </Header>
        }
      ></Container>
      <ReportMetadata report={report} />
      <Tabs
        tabs={[
          {
            id: "report-content",
            label: "Report Content",
            content: (
              <SpaceBetween size="l">
                {orderedBlocks.map((block) => (
                  <BlockView key={block.blockId} block={block} />
                ))}
              </SpaceBetween>
            ),
          },
          {
            id: "report-structure",
            label: "Report Structure",
            content: (
              <SpaceBetween size="l">
                {orderedBlocks.map((block) => (
                  <BlockStructureView key={block.blockId} block={block} allBlocks={allDefinitions} />
                ))}
              </SpaceBetween>
            ),
          },
        ]}
      />
    </SpaceBetween>
  );
};
