import Container from "@cloudscape-design/components/container";
import { Answer, CaseContext, QueryRecord } from "../../common/types/models";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";
import { FunctionComponent, useEffect, useState } from "react";
import Button from "@cloudscape-design/components/button";
import { convertCamelCaseToTitleCase, createFluxNotification } from "../../common/helpers";
import { QueryItemView } from "./QueryItemView";
import { FluxWidgetProps } from "../../common/standards";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Box from "@cloudscape-design/components/box";
import TextFilter from "@cloudscape-design/components/text-filter";
import Cards from "@cloudscape-design/components/cards";
import Link from "@cloudscape-design/components/link";
import Modal from "@cloudscape-design/components/modal";

export interface QueryRecordViewProps extends FluxWidgetProps {
  caseContext?: CaseContext;
  record?: QueryRecord;
  onChange: (record: QueryRecord) => Promise<QueryRecord | undefined>;
}

export interface QueryItem {
  field: string;
  answers: Answer[];
}

const renderValuesPreview = (answers: Answer[]) => {
  return answers
    .map((answer, _) => {
      if (answer.unit) return `${answer.value} (${answer.unit})`;
      return answer.value;
    })
    .join(", ");
};

export const QueryRecordView: FunctionComponent<QueryRecordViewProps> = ({
  caseContext,
  record,
  onChange,
  setNotifications,
}) => {
  const [updating, setUpdating] = useState(false);
  const [items, setItems] = useState<QueryItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<QueryItem[]>([]);
  const [recordFields, setRecordFields] = useState<Record<string, Answer[]>>({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalReplica, setModalReplica] = useState<QueryItem | null>(null);

  useEffect(() => {
    if (record) {
      const val = JSON.parse(record.content);
      setRecordFields(val);
      setItems(
        Object.entries(val).map(([key, value]) => ({
          field: key,
          answers: value as Answer[],
        }))
      );
      setSelectedItems([]);
    } else {
      setItems([]);
      setSelectedItems([]);
    }
  }, [record]);

  const renderModalHeader = () => {
    if (selectedItems.length !== 1) return null;
    return convertCamelCaseToTitleCase(selectedItems[0].field);
  };

  const handleQueryRecordUpdate = async () => {
    setUpdating(true);
    setModalEdit(false);
    if (!modalReplica || !record) return;
    recordFields[modalReplica.field] = modalReplica.answers;
    try {
      await onChange({ ...record, content: JSON.stringify(recordFields) });
      setModalVisible(false);
    } catch (e) {
      createFluxNotification({
        header: "Failed to update record",
        content: "Failed to update record",
        type: "error",
        id: "failed_to_update_record",
        setNotifications,
      });
    }
    setUpdating(false);
  };

  if (!caseContext) return null;
  if (items.length === 0) return null;

  return (
    <>
      <Modal
        onDismiss={() => setModalVisible(false)}
        visible={modalVisible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => {
                  setModalEdit(false);
                  setModalVisible(false);
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" loading={updating} onClick={handleQueryRecordUpdate}>
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={renderModalHeader()}
      >
        <QueryItemView
          item={modalReplica}
          inEdit={modalEdit}
          onChange={(replica) => {
            setModalReplica(replica);
          }}
        />
      </Modal>

      <Cards
        header={
          <Header
            // counter={selectedItems?.length ? "(" + selectedItems?.length + "/10)" : "(10)"}
            description={`Query ID: ${record?.queryId}`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown
                  onItemClick={(e) => {
                    setModalVisible(true);
                    setModalReplica(JSON.parse(JSON.stringify(selectedItems[0])));
                    if (e.detail.id === "details") {
                      setModalEdit(false);
                    } else if (e.detail.id === "edit") {
                      setModalEdit(true);
                    }
                  }}
                  items={[
                    {
                      text: "Details",
                      id: "details",
                      disabled: selectedItems.length !== 1,
                    },
                    {
                      text: "Edit",
                      id: "edit",
                      disabled: selectedItems.length !== 1,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>
                <Button>Delete</Button>
                <Button variant="primary">Add</Button>
              </SpaceBetween>
            }
          >
            Explore Record Items
          </Header>
        }
        onSelectionChange={({ detail }) => setSelectedItems(detail?.selectedItems ?? [])}
        selectedItems={selectedItems}
        cardDefinition={{
          header: (item) => <Link fontSize="heading-m">{convertCamelCaseToTitleCase(item.field)}</Link>,
          sections: [
            {
              id: "values",
              header: "Values",
              content: (item) => renderValuesPreview(item.answers),
            },
          ],
        }}
        cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
        items={items}
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="field"
        visibleSections={["values"]}
        filter={<TextFilter filteringPlaceholder="Find resources" filteringText="" />}
        // pagination={
        //   <Pagination currentPageIndex={1} pagesCount={2} />
        // }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: 6,
              visibleContent: ["description", "type", "size"],
            }}
            pageSizePreference={{
              title: "Page size",
              options: [
                { value: 6, label: "6 resources" },
                { value: 12, label: "12 resources" },
              ],
            }}
            visibleContentPreference={{
              title: "Select visible content",
              options: [
                {
                  label: "Main distribution properties",
                  options: [
                    {
                      id: "description",
                      label: "Description",
                    },
                    { id: "type", label: "Type" },
                    { id: "size", label: "Size" },
                  ],
                },
              ],
            }}
          />
        }
      />
    </>
  );
};
