import { FunctionComponent, useEffect, useState } from "react";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import SpaceBetween from "@cloudscape-design/components/space-between";
import FileUpload from "@cloudscape-design/components/file-upload";

interface UploadModelFormProps {
  onUpload: (file: File, name: string) => Promise<void>;
  uploading: boolean;
}

export const UploadModelForm: FunctionComponent<UploadModelFormProps> = ({
  onUpload,
  uploading,
}) => {
  const [source, setSource] = useState<File[]>([]);
  const [modelName, setModelName] = useState<string>("");

  useEffect(() => {
    if (source.length > 0) {
      const file = source[0];
      const fileName = file.name;
      const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf(".")) || fileName;
      setModelName(nameWithoutExtension);
    } else {
      setModelName("");
    }
  }, [source]);

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            variant="primary"
            loading={uploading}
            disabled={!source.length || !modelName}
            onClick={() => source[0] && onUpload(source[0], modelName)}
          >
            Upload Model
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <FormField label="XLSX File" description="Select an Excel spreadsheet model to upload">
          <FileUpload
            onChange={({ detail }) => setSource(detail.value)}
            value={source}
            i18nStrings={{
              uploadButtonText: () => "Choose file",
              dropzoneText: () => "Drop XLSX file to upload",
              removeFileAriaLabel: () => `Remove file`,
              limitShowFewer: "Show fewer files",
              limitShowMore: "Show more files",
              errorIconAriaLabel: "Error",
              warningIconAriaLabel: "Warning",
            }}
            showFileLastModified
            showFileSize
            tokenLimit={1}
            constraintText="Only XLSX format is supported (*.xlsx)"
            accept=".xlsx"
          />
        </FormField>
        <FormField label="Model Name" description="Enter a name for this model">
          <Input
            value={modelName}
            onChange={({ detail }) => setModelName(detail.value)}
            placeholder="Enter model name"
          />
        </FormField>
      </SpaceBetween>
    </Form>
  );
};