import axios from "axios";
import { EndpointConfigs } from "./endpointConfigs";
import {
  DeleteReportRequest,
  GetReportRequest,
  StartWorkflowRequest,
  UploadExcelModelRequest,
  GetExcelModelRequest,
  DeleteExcelModelRequest,
} from "../common/types/requests";
import { ProcessModelJob, Report } from "../common/types/models";
import {
  GetReportResponse,
  StartWorkflowResponse,
  UploadExcelModelResponse,
  GetExcelModelResponse,
  ListExcelModelsResponse,
} from "../common/types/responses";

const REPORT_GENERATION_API_URL = EndpointConfigs.reportGenerationApiUrl();

export const startWorkflow = async (
  accessToken: string,
  request: StartWorkflowRequest
): Promise<StartWorkflowResponse> => {
  const response = await axios.post(REPORT_GENERATION_API_URL, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "StartWorkflow",
      Authorization: accessToken,
    },
  });
  if (response.status !== 200) {
    console.error("Failed to start workflow");
    throw new Error("Failed to start workflow");
  }
  return response.data as StartWorkflowResponse;
};

export const listReports = async (accessToken: string): Promise<Report[]> => {
  const response = await axios.post(
    REPORT_GENERATION_API_URL,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "ListReports",
        Authorization: accessToken,
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to list reports");
    throw new Error("Failed to list reports");
  }
  return response.data.reports as Report[];
};

export const getReport = async (accessToken: string, request: GetReportRequest): Promise<GetReportResponse> => {
  const response = await axios.post(REPORT_GENERATION_API_URL, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "GetReport",
      Authorization: accessToken,
    },
  });
  if (response.status !== 200) {
    console.error("Failed to get report");
    throw new Error("Failed to get report");
  }
  return response.data as GetReportResponse;
};

export const deleteReport = async (accessToken: string, request: DeleteReportRequest): Promise<void> => {
  const response = await axios.post(REPORT_GENERATION_API_URL, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "DeleteReport",
      Authorization: accessToken,
    },
  });
  if (response.status !== 200) {
    console.error("Failed to delete report");
    throw new Error("Failed to delete report");
  }
};

export const listProcessModelJobs = async (accessToken: string, modelId: string): Promise<ProcessModelJob[]> => {
  const response = await axios.post(
    REPORT_GENERATION_API_URL,
    { modelId },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "ListProcessModelJobs",
        Authorization: accessToken,
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to list process model jobs");
    throw new Error("Failed to list process model jobs");
  }
  return response.data.jobs as ProcessModelJob[];
};

export const getProcessModelJob = async (
  accessToken: string,
  modelId: string,
  jobId: string
): Promise<ProcessModelJob> => {
  const response = await axios.post(
    REPORT_GENERATION_API_URL,
    { modelId, jobId },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "GetProcessModelJob",
        Authorization: accessToken,
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to get process model job");
    throw new Error("Failed to get process model job");
  }
  return response.data.job as ProcessModelJob;
};

export const deleteProcessModelJob = async (accessToken: string, modelId: string, jobId: string): Promise<void> => {
  const response = await axios.post(
    REPORT_GENERATION_API_URL,
    { modelId, jobId },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "DeleteProcessModelJob",
        Authorization: accessToken,
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to delete process model job");
    throw new Error("Failed to delete process model job");
  }
};

// Excel Model API Functions
export const uploadExcelModel = async (
  accessToken: string,
  request: UploadExcelModelRequest
): Promise<UploadExcelModelResponse> => {
  const response = await axios.post(REPORT_GENERATION_API_URL, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "UploadExcelModel",
      Authorization: accessToken,
    },
  });

  if (response.status !== 200) {
    console.error("Failed to delete process model job");
    throw new Error("Failed to delete process model job");
  }
  return response.data as UploadExcelModelResponse;
};

export const listExcelModels = async (accessToken: string): Promise<ListExcelModelsResponse> => {
  const response = await axios.post(
    REPORT_GENERATION_API_URL,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "ListExcelModels",
        Authorization: accessToken,
      },
    }
  );
  return response.data as ListExcelModelsResponse;
};

export const getExcelModel = async (
  accessToken: string,
  request: GetExcelModelRequest
): Promise<GetExcelModelResponse> => {
  const response = await axios.post(REPORT_GENERATION_API_URL, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "GetExcelModel",
      Authorization: accessToken,
    },
  });
  return response.data as GetExcelModelResponse;
};

export const deleteExcelModel = async (accessToken: string, request: DeleteExcelModelRequest): Promise<void> => {
  await axios.post(REPORT_GENERATION_API_URL, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "DeleteExcelModel",
      Authorization: accessToken,
    },
  });
};
