import { FunctionComponent, useEffect, useReducer } from "react";
import { FluxWidgetProps } from "../../common/standards";

import FileDropzone from "@cloudscape-design/components/file-dropzone";
import FileInput from "@cloudscape-design/components/file-input";
import FileTokenGroup from "@cloudscape-design/components/file-token-group";
import CodeView from "@cloudscape-design/code-view/code-view";
import { useCognito } from "../../auth/cognito";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Textarea from "@cloudscape-design/components/textarea";
import Box from "@cloudscape-design/components/box";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import Wizard from "@cloudscape-design/components/wizard";
import { CreateConfigurationRequest } from "../../common/types/requests";

export interface CreateConfigurationWizardProps extends FluxWidgetProps {
  onSubmit: (c: CreateConfigurationRequest) => Promise<void>;
  onCancel: () => void;
}

interface ConfigurationState {
  configurationId: string;
  name: string;
  type: string;
  description: string;
  content: string;
  uploadedFiles: File[];
  creating: boolean;
}

const initialState: ConfigurationState = {
  configurationId: "",
  name: "Untitled",
  type: "",
  description: "",
  content: "",
  uploadedFiles: [],
  creating: false,
};

type ConfigurationAction =
  | { type: "SET_NAME"; payload: string }
  | { type: "SET_CONFIGURATION_ID"; payload: string }
  | { type: "SET_TYPE"; payload: string }
  | { type: "SET_DESCRIPTION"; payload: string }
  | { type: "SET_CONTENT"; payload: string }
  | { type: "SET_FILES"; payload: File[] }
  | { type: "SET_CREATING"; payload: boolean }
  | { type: "RESET" };

const configReducer = (state: ConfigurationState, action: ConfigurationAction): ConfigurationState => {
  switch (action.type) {
    case "SET_NAME":
      return { ...state, name: action.payload };
    case "SET_CONFIGURATION_ID":
      return { ...state, configurationId: action.payload };
    case "SET_TYPE":
      return { ...state, type: action.payload };
    case "SET_DESCRIPTION":
      return { ...state, description: action.payload };
    case "SET_CONTENT":
      return { ...state, content: action.payload };
    case "SET_FILES":
      return { ...state, uploadedFiles: action.payload };
    case "SET_CREATING":
      return { ...state, creating: action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export const CreateConfigurationWizard: FunctionComponent<CreateConfigurationWizardProps> = ({
  onSubmit,
  onCancel,
}) => {
  const [state, dispatch] = useReducer(configReducer, initialState);
  const { authUser } = useCognito();

  // Generate configuration ID when name changes or user changes
  useEffect(() => {
    if (authUser) {
      const configId = generateConfigurationId(authUser.username, state.name);
      dispatch({ type: "SET_CONFIGURATION_ID", payload: configId });
    }
  }, [authUser, state.name]);

  // Pure function to generate configuration ID
  const generateConfigurationId = (username: string, name: string): string => {
    const today = new Date().toISOString().split("T")[0];
    return `${username.toLowerCase()}.${name.toLowerCase().replace(/\s+/g, "-")}.${today}`;
  };

  const handleFileUpload = (files: File[]) => {
    if (files.length === 0) return;

    dispatch({ type: "SET_FILES", payload: files });

    const file = files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target ? (e.target.result as string) : "";
      dispatch({ type: "SET_CONTENT", payload: content });
    };

    reader.readAsText(file);
  };

  const handleSubmit = async () => {
    dispatch({ type: "SET_CREATING", payload: true });

    try {
      await onSubmit({
        configurationId: state.configurationId,
        name: state.name,
        type: state.type,
        description: state.description,
        content: state.content,
      });

      dispatch({ type: "RESET" });
    } finally {
      dispatch({ type: "SET_CREATING", payload: false });
    }
  };

  const handleCancel = () => {
    dispatch({ type: "RESET" });
    onCancel();
  };

  const steps = [
    {
      title: "Configuration Details",
      content: (
        <Container>
          <SpaceBetween size="m">
            <FormField label="Name">
              <Input
                value={state.name}
                onChange={(event) => dispatch({ type: "SET_NAME", payload: event.detail.value })}
              />
            </FormField>
            <FormField label="Custom ID">
              <Input
                value={state.configurationId}
                onChange={(event) => dispatch({ type: "SET_CONFIGURATION_ID", payload: event.detail.value })}
              />
            </FormField>
            <FormField label="Type">
              <Input
                value={state.type}
                onChange={(event) => dispatch({ type: "SET_TYPE", payload: event.detail.value })}
              />
            </FormField>
            <FormField label="Description">
              <Textarea
                value={state.description}
                onChange={(event) => dispatch({ type: "SET_DESCRIPTION", payload: event.detail.value })}
              />
            </FormField>
            <FormField label="Upload Configuration File">
              <SpaceBetween size="l">
                <FileDropzone onChange={({ detail }) => handleFileUpload(detail.value)}>
                  <SpaceBetween size="xxs" alignItems="center">
                    <Box color="inherit">Drop file here or select from below</Box>
                    <FileInput
                      value={state.uploadedFiles}
                      onChange={({ detail }) => handleFileUpload(detail.value)}
                      multiple={false}
                    >
                      Choose file
                    </FileInput>
                  </SpaceBetween>
                </FileDropzone>
                <FileTokenGroup
                  items={state.uploadedFiles.map((file) => ({ file }))}
                  onDismiss={({ detail }) => {
                    const newFiles = state.uploadedFiles.filter((_, index) => index !== detail.fileIndex);
                    dispatch({ type: "SET_FILES", payload: newFiles });
                    if (newFiles.length === 0) {
                      dispatch({ type: "SET_CONTENT", payload: "" });
                    }
                  }}
                  i18nStrings={{
                    removeFileAriaLabel: () => "Remove file",
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                    warningIconAriaLabel: "Warning",
                  }}
                />
              </SpaceBetween>
            </FormField>
          </SpaceBetween>
        </Container>
      ),
    },
    {
      title: "Review & Submit",
      content: (
        <SpaceBetween size="s">
          <KeyValuePairs
            columns={3}
            items={[
              { label: "Name", value: state.name },
              { label: "Type", value: state.type },
              { label: "Description", value: state.description },
            ]}
          ></KeyValuePairs>
          <FormField label="Content">
            <CodeView content={state.content} lineNumbers={true} />
          </FormField>
        </SpaceBetween>
      ),
    },
  ];

  return (
    <Container header={<Header variant="h2">Create New Configuration</Header>}>
      <Wizard
        steps={steps}
        submitButtonText="Create"
        isLoadingNextStep={state.creating}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Container>
  );
};
